import createSagaMiddleware from "redux-saga";
import { applyMiddleware, compose, createStore } from "redux";
import rootReducer from "./root-reducer";
import rootSaga from "./saga";
import { tokenLoadTokenAction } from "./token/action";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
const enhancer = compose(applyMiddleware(...middlewares));

const initialState = {};

const store = createStore(rootReducer, initialState, enhancer);
sagaMiddleware.run(rootSaga);

store.dispatch(tokenLoadTokenAction.request([], "init"));

export default store;
