import { useEffect, useRef, useState } from "react";

export default function useCountDown() {
  const [countDown, setCountDown] = useState(0);
  const intervalRef = useRef<number | undefined>();

  const start = (seconds: number) => {
    stop();

    setCountDown(seconds);

    // @ts-ignore
    intervalRef.current = setInterval(() => {
      setCountDown((count) => count - 1);
    }, 1000);
  };

  const stop = () => {
    clearInterval(intervalRef.current);
  };

  useEffect(() => {
    if (countDown <= 0) {
      stop();
    }
  }, [countDown]);

  useEffect(() => {
    return () => stop();
  }, []);

  return [countDown, start, stop] as const;
}
