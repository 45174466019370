import React, { CSSProperties } from "react";
import styles from "./index.module.scss";
import ReactLoading from "react-loading";

export const CommonButton = ({
  title,
  style,
  onClick,
  isLoading,
  className,
  white = false,
}: {
  isLoading?: boolean;
  style?: CSSProperties;
  title: string;
  className?: string;
  onClick?: () => void;
  white?: boolean;
}) => {
  return (
    <div
      style={style}
      className={
        className + " " + (white ? styles.container__white : styles.container)
      }
      onClick={onClick}
    >
      <span style={{ opacity: isLoading ? 0 : 1 }}>{title}</span>
      {isLoading && <LoadingIndicator className={styles.loading} />}
    </div>
  );
};

export const LoadingIndicator = ({ className }: { className?: string }) => {
  return (
    <ReactLoading className={className} type={"spin"} width={20} height={20} />
  );
};
