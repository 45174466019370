import { all, call, put, takeEvery } from "redux-saga/effects";
import { phoneAuthPostSmsAction } from "./action";
import api from "../storeConfig";

export const postSmsRequest = function* (
  action: ReturnType<typeof phoneAuthPostSmsAction.request>
) {
  try {
    const { phone, ...registerData } = action.payload[0];
    action.payload[0] = { phone };

    let response = yield call(() =>
      api.phoneAuth.methods.postSms(...action.payload)
    );

    yield put(
      phoneAuthPostSmsAction.success(
        { ...response.data, registerData },
        action.meta
      )
    );
  } catch (error) {
    yield put(phoneAuthPostSmsAction.failure(error, action.meta));
  }
};

export const phoneAuthSaga = function* () {
  yield all([takeEvery(phoneAuthPostSmsAction.request, postSmsRequest)]);
};
