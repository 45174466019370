import { createReducer, getType } from 'typesafe-actions'
import AsyncAction, { AsyncActionWithCancel } from './async-action'

export default function createLoadingReducer(
  ...actions: (
    | AsyncAction<any, any, any, any, any, any>
    | AsyncActionWithCancel<any, any, any, any, any, any, any, any>
  )[]
) {
  return createReducer<Set<string>>(new Set())
    .handleAction(
      actions.map((act) => act.request),
      (state, action) => {
        const newState = state && new Set(state)
        newState?.add(action.meta)
        return newState
      },
    )
    .handleAction(
      actions.flatMap((act) => [
        act.success,
        act.failure,
        ...(act.cancel ? [act.cancel] : []),
      ]),
      (state, action) => {
        const newState = state && new Set(state)

        newState?.delete(action.meta)

        return newState
      },
    )
}
