import styles from "./index.module.scss";
import { PropsWithChildren, useEffect, useState } from "react";
import { useUser } from "../../utils/useUser";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import Lock from "../../assets/lockIcon.png";
import { ReactComponent as Menu } from "../../assets/menu.svg";
import { ReactComponent as Close } from "../../assets/close.svg";
import { ReactComponent as Account } from "../../assets/account.svg";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { AwayButton } from "../awayButton";

const useToggle = (initialState = false) => {
  const [isOpen, setOpen] = useState(initialState);
  const toggle = () => setOpen(!isOpen);
  return [isOpen, toggle, setOpen] as const;
};

const Links = ({ onClick }: { onClick?: () => void }) => (
  <>
    <HashLink className={styles.link} to="/#program" smooth onClick={onClick}>
      о программе
    </HashLink>
    <HashLink className={styles.link} to="/#prizes" smooth onClick={onClick}>
      призы
    </HashLink>
    <HashLink className={styles.link} to="/#faq" smooth onClick={onClick}>
      faq
    </HashLink>
    <HashLink className={styles.link} to="/winners" smooth onClick={onClick}>
      победители
    </HashLink>
    {/*<AnchorLink name="program" onClick={onClick}>*/}
    {/*  победители*/}
    {/*</AnchorLink>*/}
  </>
);

const LoginButton = ({ onClick }: { onClick?: () => void }) => (
  <a
    href="/login"
    className={styles.link}
    onClick={(ev) => {
      ev.preventDefault();
      onClick?.();
    }}
  >
    <img src={Lock} className={styles.lockIcon} />
    войти
  </a>
);

const ProfileButton = ({
  children,
}: PropsWithChildren<{ onClick?: () => void }>) => (
  <HashLink to="/profile/account#top" className={styles.link}>
    <Account />
    {children}
  </HashLink>
);

export const Header = () => {
  const { login, firstName } = useUser();
  const [isMenuOpen, toggleMenu, setOpen] = useToggle();
  const [isScrolledDown, setIsScrolledDown] = useState(false);
  useEffect(() => {
    const onScroll = () => {
      setIsScrolledDown(window.scrollY > 0);
    };
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);
  return (
    <>
      <div
        className={[
          styles.container,
          isScrolledDown || isMenuOpen ? styles.scrolled : "",
        ].join(" ")}
      >
        <div className={styles.content}>
          <HashLink to="/#top" smooth onClick={() => setOpen(false)}>
            <Logo className={styles.logo} />
          </HashLink>
          <div className={styles.desktop}>
            <div className={styles.space} />
            <div className={styles.desktopLinks}>
              <Links />
            </div>
            <div className={styles.space} />
            <AwayButton />
            <div className={styles.space} />
            {firstName ? (
              <ProfileButton>{firstName}</ProfileButton>
            ) : (
              <LoginButton onClick={login} />
            )}
          </div>
          <div className={styles.mobile}>
            <div className={styles.space} />
            {firstName ? (
              <HashLink to="/profile/account#top" className={styles.link}>
                <Account className={styles.mobileButton} />
              </HashLink>
            ) : (
              <img
                onClick={login}
                src={Lock}
                className={styles.lockIcon + " " + styles.mobileButton}
              />
            )}
            {isMenuOpen ? (
              <Close
                onClick={toggleMenu}
                className={styles.mobileButton}
                style={{ marginLeft: 4 }}
              />
            ) : (
              <Menu onClick={toggleMenu} className={styles.mobileButton} />
            )}
          </div>
        </div>
        <div
          className={[styles.mobileLinks, isMenuOpen ? styles.open : ""].join(
            " "
          )}
        >
          <AwayButton />
          <Links onClick={toggleMenu} />
        </div>
      </div>
      <div
        onClick={toggleMenu}
        className={[styles.overlay, isMenuOpen ? styles.open : ""].join(" ")}
      />
    </>
  );
};
