import { combineReducers } from "redux";

import { StateType } from "typesafe-actions";
import { tokenReducer } from "./token/reducer";
import { phoneAuthReducer } from "./phoneAuth/reducer";
import { userReducer } from "./user/reducer";
import { testsReducer } from "./tests/reducer";
import { receiptsReducer } from "./receipts/reducer";

const reducers = {
  token: tokenReducer,
  phoneAuth: phoneAuthReducer,
  user: userReducer,
  tests: testsReducer,
  receipts: receiptsReducer,
};

const rootReducer = combineReducers(reducers);

export default rootReducer;

export type RootState = StateType<typeof reducers>;
