import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { MainScreen } from "./main";
import { ContentRestrictionScreen } from "./main/contentRestriction";
import { LoginScreen } from "./auth/login";
import { SmsConfirmScreen } from "./auth/smsConfirm";
import { RegisterScreen } from "./auth/register";
import { useSelector } from "../utils/redux-utils";
import { RootState } from "../store/root-reducer";
import { ProfileScreen } from "./profile/profile";
import { PrivacyPolicyScreen } from "./support/privacy";
import { RulesScreen } from "./support/rules";
import { AccountScreen } from "./profile/account";
import { ReceiptsScreen } from "./profile/receipts";
import { HistoryScreen } from "./profile/history";
import { UploadReceiptScreen } from "./main/uploadReceipt";
import { FillMissedScreen } from "./main/fillMissed";
import { WinnersScreen } from "./winners/winner";

export const MainNavigation = () => {
  const user = useSelector((store: RootState) => store.user.data);

  const isLoggedIn = Boolean(user);

  const location = useLocation();
  const mainLocation = location.state?.mainLocation;

  return (
    <>
      <Routes location={mainLocation || location}>
        <Route element={<MainScreen />} path={"/"} />
        <Route element={<ProfileScreen />} path={"/profile"}>
          <Route element={<AccountScreen />} path={"account"} />
          <Route element={<HistoryScreen />} path={"history"} />
          <Route element={<ReceiptsScreen />} path={"receipts"} />
        </Route>
        <Route element={<WinnersScreen />} path={"/winners"} />
        <Route element={<RulesScreen />} path={"/rules"} />
        <Route element={<PrivacyPolicyScreen />} path={"/privacy-policy"} />
      </Routes>

      {mainLocation && (
        <Routes>
          <Route
            element={<ContentRestrictionScreen />}
            path={"/content-restriction"}
          />
          <Route element={<FillMissedScreen />} path={"/fill-missed"} />
          <Route element={<UploadReceiptScreen />} path={"/upload-receipt"} />
          {!isLoggedIn && (
            <>
              <Route element={<LoginScreen />} path={"/login"} />
              <Route element={<SmsConfirmScreen />} path={"/sms-confirm"} />
              <Route element={<RegisterScreen />} path={"/register"} />
            </>
          )}
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      )}
    </>
  );
};
