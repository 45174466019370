import { phoneAuthPostSmsAction } from "./action";
import api from "../storeConfig";
import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import { RequestState } from "../../libraries/packages/redux-utils/src/request/create-request-reducer";
import createLoadingReducer from "../../libraries/packages/redux-utils/src/request/create-loading-reducer";
import createOperationReducer from "../../libraries/packages/redux-utils/src/request/create-operation-reducer";
import createErrorReducer from "../../libraries/packages/redux-utils/src/request/create-error-reducer";

export const phoneAuthReducer = combineReducers<
  RequestState<typeof api.phoneAuth.type>
>({
  data: createReducer(null).handleAction(
    [phoneAuthPostSmsAction.success],
    (state, action) => action.payload
  ),
  isLoading: createLoadingReducer(phoneAuthPostSmsAction),
  lastOpId: createOperationReducer(phoneAuthPostSmsAction),
  error: createErrorReducer(phoneAuthPostSmsAction),
});
