import React, { useEffect, useRef, useState } from "react";
import { ModalWrapper } from "../../components/modalWrapper/modalWrapper";
import {
  ModalHeader,
  ModalText,
} from "../../components/modalTypography/modalTypography";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CommonButton } from "../../components/commonButton";
import { CommonTextInput } from "../../components/TextInput/commonTextInput";
import useUniqueDispatch, {
  isData,
  isLoading,
  useSelector,
} from "../../utils/redux-utils";
import { receiptsPostReceiptAction } from "../../store/receipts/action";
import { RootState } from "../../store/root-reducer";
import styles from "./modals.module.scss";
import { AwayLink } from "../../components/awayButton";

export const UploadReceiptScreen = () => {
  const navigate = useNavigate();
  const [query] = useSearchParams();

  const inputFile = useRef<HTMLInputElement | null>(null);

  const loadFileHandler = () => {
    inputFile.current?.click();
  };

  const [postReceiptToken, postReceipt] = useUniqueDispatch(
    receiptsPostReceiptAction.request
  );

  const receiptRequest = useSelector((store: RootState) => store.receipts);

  const newData = isData(receiptRequest, postReceiptToken);
  const isSending = isLoading(receiptRequest, postReceiptToken);

  const [file, setFile] = useState<File | null>(null);

  const [orderNumber, setOrderNumber] = useState("");

  const submitHandler = () => {
    postReceipt([{ file, orderNumber, testId: query.get("test_id") }]);
  };

  useEffect(() => {
    if (newData) navigate("/");
  }, [receiptRequest]);

  return (
    <ModalWrapper
      onClose={() => navigate("/")}
      style={{ paddingLeft: 15, paddingRight: 15 }}
    >
      <ModalHeader>Загрузка чека</ModalHeader>
      <div className={styles.layout}>
        <ModalText style={{ flex: 1, marginTop: 12 }}>
          Чтобы посмотреть остальные видеоуроки и&nbsp;принять участие
          в&nbsp;розыгрыше призов&nbsp;&mdash; покупайте продукцию на&nbsp;
          <AwayLink /> от&nbsp;5000 рублей и загружайте чек.
        </ModalText>
        <div className={styles.controls}>
          <CommonButton onClick={loadFileHandler} title="Выбрать файл" white />
          <ModalText style={{ textAlign: "center", fontWeight: 600 }}>
            или
          </ModalText>
          <CommonTextInput
            value={orderNumber}
            onChange={(event) => setOrderNumber(event.target.value)}
            label="Номер заказа"
          />
          <CommonButton
            isLoading={isSending}
            onClick={submitHandler}
            title="Отправить"
          />

          <input
            type="file"
            onChange={(event) => setFile(event.target.files?.[0] || null)}
            ref={inputFile}
            style={{ display: "none" }}
          />
        </div>
      </div>
      <ModalText></ModalText>
    </ModalWrapper>
  );
};
