import PhoneInput, { Value } from "react-phone-number-input";
import { CommonTextInput } from "./commonTextInput";
import { CSSProperties } from "react";

export const CustomPhoneInput = ({
  value,
  setValue,
  label,
  error,
  style,
  disabled = false,
}: {
  value: Value | undefined;
  setValue: (val: Value) => void;
  label?: string;
  style?: CSSProperties;
  error?: string;
  disabled?: boolean;
}) => {
  return (
    <PhoneInput
      country={"RU"}
      style={style}
      defaultCountry={"RU"}
      disabled={disabled}
      countrySelectComponent={() => <></>}
      inputComponent={CommonTextInput}
      label={label}
      error={error}
      value={value}
      international
      placeholder={"+7"}
      // @ts-ignore
      onChange={setValue}
    />
  );
};
