import React from "react";
import { Header } from "../../components/header/header";
import { Footer } from "../../components/footer/footer";
import styles from "./index.module.scss";

export const RulesScreen = () => (
  <>
    <Header />
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>Правила акции</h1>
        <p>
          <strong>ПРАВИЛА ПРОВЕДЕНИЯ РЕКЛАМНОЙ АКЦИИ</strong>
        </p>
        <p>
          <strong>&laquo;ВИНЛАБ CARE!&raquo;</strong>
        </p>
        <p>
          Рекламная акция &laquo;ВИНЛАБ CARE&raquo; проводится с целью
          формирования и поддержания интереса к деятельности рекламодателя
          Акции. Участие в Акции не связано с внесением платы Участниками за
          участие в мероприятии и не основано на риске.
        </p>
        <p>
          Принимая участие в рекламной акции &laquo;ВИНЛАБ CARE&raquo; (далее
          &ndash; Акция), Участники
        </p>
        <p>
          полностью соглашаются с настоящими правилами (далее &ndash; Правила).
        </p>
        <p>
          Акция проводится на территории Российской Федерации, регламентирована
          действующим законодательством Российской Федерации, а также настоящими
          Правилами, является рекламным стимулирующим ст. 9 Федерального закона
          РФ от 13.03.2006 N 38-ФЗ &laquo;О рекламе&raquo; мероприятием, не
          является публичным конкурсом в смысле гл. 57 Гражданского кодекса РФ и
          не является лотереей в смысле Федерального закона РФ от 11.11.2003 N
          138-ФЗ &laquo;О лотереях&raquo;.
          <br />
          <br />
          <strong>1. Общие положения проведения Акции</strong>
        </p>
        <p>1.1. Наименование Акции: &laquo;ВИНЛАБ CARE&raquo;.</p>
        <p>
          1.2. Территория проведения Акции &ndash; города, в которых
          представлена продукция бренда, включая все регионы Российской
          Федерации (далее &ndash; Территория).
        </p>
        <p>
          1.3. Организатором Акции является: ООО &laquo;Скай-ИТ&raquo;.
          ИНН:5408227631, КПП:540601001, Юридический адрес: 630099,
          Новосибирская обл., г.Новосибирск, ул. Ядринцевская, д. 54 этаж 1 офис
          4
        </p>
        <p>,(далее &ndash; Организатор).</p>
        <p>
          Рекламодателем Акции является: ООО
          &laquo;ВИНЛАБ-ЗАПАД&raquo;.ИНН:7719191685, КПП 500601001, Юридический
          адрес:143090 Россия, Московская обл. г. Краснознаменск, ул. Победы, д.
          26
        </p>
        <p>, (далее &ndash; Рекламодатель).</p>
        <p>1.4. Сроки проведения Акции:</p>
        <p>
          1.4.1. Срок проведения акции: с &laquo;05&raquo; декабря 2023 г. по
          &laquo;30&raquo; января 2023 года (включительно).
        </p>
        <p>
          1.4.2. Период совершения покупки и регистрации кассовых чеков (далее
          &ndash; &laquo;Период регистрации чеков&raquo;): с 00 часов 00 минут
          00 секунд &laquo;05&raquo; декабря 2023 г. по 23 часа 59 минут 59
          секунд &laquo;31&raquo; декабря 2023 года (по московскому времени).
        </p>
        <p>
          1.4.3. Период одобрения чеков: с 00 часов 00 минут 00 секунд
          &laquo;05&raquo; декабря 2023 г. по 23 часа 59 минут 59 секунд
          &laquo;31&raquo; декабря 2023 года (по московскому времени).
        </p>
        <p>
          1.4.4. Период выдачи призов Победителям Акции: с &laquo;05&raquo;
          декабря2023 г. по &laquo;30&raquo; января 2024 года.
        </p>
        <p>
          1.4.5. Организатор оставляет за собой право изменить срок проведения
          Акции.
        </p>
        <p>1.5. Способы информирования Участников Акции.</p>
        <p>
          Участники Акции будут информироваться о Правилах и сроках проведения
          Акции в сети Интернет: на сайте winelab-care.ru путем размещения
          полных Правил Акции. Информация о получателях призов всех уровней
          будет размещена на сайте winelab-care.ru в течение 5-и (пяти) рабочих
          дней с момента их определения.
          <br />
          <br />
          <strong>2. Условия участия в Акции и Призовой фонд</strong>
        </p>
        <p>
          2.1. Участниками Акции могут быть совершеннолетние дееспособные
          физические лица, являющиеся гражданами Российской Федерации и
          постоянно проживающие на территории Российской Федерации (далее
          &ndash; &laquo;Участник&raquo;).
        </p>
        <p>2.2. К участию в Акции не допускаются:</p>
        <p>
          &bull; Лица, признанные в установленном порядке аффилированными с
          Организатором и/или Рекламодателем;
        </p>
        <p>
          &bull; Работники и представители третьих лиц, имеющие договорные
          отношения с Организатором и/или Рекламодателем и связанные с
          организацией и/или проведением Акции, а также члены их семей.
        </p>
        <p>2.3. Участники Акции имеют, в частности, следующие права:</p>
        <p>
          &bull; право на получение информации об Акции в соответствии с
          настоящими Правилами;
        </p>
        <p>
          &bull; право на получение призов Акции в случае, если Участник будет
          признан выигравшим, в соответствии с настоящими Правилами;
        </p>
        <p>
          &bull; иные права, предусмотренные настоящими Правилами и действующим
          законодательством Российской Федерации.
        </p>
        <p>2.4. Участники Акции несут, в частности, следующие обязанности:</p>
        <p>&bull; соблюдать Правила Акции во время ее проведения;</p>
        <p>
          &bull; предоставлять Организатору и Рекламодателю достоверную
          информацию о себе в соответствии с Правилами Акции;
        </p>
        <p>
          &bull; иные обязанности, предусмотренные настоящими Правилами и
          действующим законодательством Российской Федерации.
        </p>
        <p>
          2.5. В Акции участвуют Чеки или УПД или Номер заказа,полученные за
          любые покупки от 5000 рублей на сайте
          <a href="https://b2b.winelab.ru/">https://b2b.winelab.ru/</a>,
          пользователям также нужно успешно (ответили правильно на все вопросы в
          тесте на сайте winelab-care.ru) пройти тест, после просмотра
          видеоурока на сайте. <br />
          <br />
          2.6. Призовой фонд Акции формируется за счет средств Организатора и
          состоит из:
        </p>
        <p>
          2.6.1. Гарантированный приз (далее &laquo;Гарантированный приз&raquo;)
          &ndash; набор стикеров в мессенджере Telegram.
        </p>
        <p>
          Приз отправляется получателю на почту, указанную при регистрации в
          течение 5-и (пяти) рабочих дней .
        </p>
        <p>
          Получателем Гарантированного приза - набор стикеров в мессенджере
          Telegram становится участник, зарегистрировавшийся на сайте
          winelab-care.ru и успешно (ответив правильно на все вопросы) прошедший
          тест после видеоурока.
        </p>
        <p>
          На 1 зарегистрированного пользователя начисляется 1 Гарантированный
          приз.
        </p>
        <p>
          2.6.2. Еженедельный приз (далее &laquo;Еженедельный приз&raquo;)
          &ndash; Еженедельный приз, состоящий из одной части &ndash;в виде
          электронных сертификатов Золотое яблоко по 3000 (Три тысячи) рублей 00
          коп.
        </p>
        <p>
          Еженедельный приз (далее &laquo;Еженедельный приз&raquo;) &ndash;
          Еженедельный приз, состоящий из двух частей &ndash;в виде электронного
          сертификата Ozon (https://www.ozon.ru/) по 5000 (Пять тысяч) рублей 00
          копеек,а также вторая неотъемлемая часть приза &ndash; денежная,
          которая в момент ее выдачи удерживается Организатором в целях
          выполнения функций налогового агента, согласно разделу 6 настоящих
          Правил.
          <br />
          Вручение Еженедельных призов, указанных в п. 2.6.2. настоящих Правил,
          осуществляется путем отправки электронного сертификата на указанную
          пользователем почту в течение 5-и (пяти) рабочих дней с момента
          предоставления необходимых документов от получателя призов.
        </p>
        <p>
          Получателями Еженедельного приза становятся 3 (три) Участника каждую
          неделю: 2 (Два) получателя приза электронного сертификатаЗолотое
          яблоко (https://goldapple.ru/) по 3 000 (Три тысячи) рублей 00 коп и 1
          Получатель (Один) электронного сертификата Ozon (https://www.ozon.ru/)
          по 5000 (Пять тысяч) рублей 00 копеек.
        </p>
        <p>
          За весь период проведения Акции, один Участник может получить не более
          1 (одного) приза данной категории на 1 одобренный чек.
        </p>
        <p>
          Общее количество еженедельных призов за весь период Акции составляет 9
          (девять) штук.
        </p>
        <p>
          Общее количество электронных сертификатов Золотое яблоко
          (https://goldapple.ru/) по 3 000 (Три тысячи) рублей 00 коп составляет
          6 (шесть) штук. Общее количество электронных сертификатовOzon
          (https://www.ozon.ru/) по 5 000 (Пять тысяч) рублей 00 копеек
          составляет 3 (три) штуки
        </p>
        <p>
          2.6.3. Главный приз (далее &laquo;Главный приз&raquo;), состоящий из
          двух частей: смартфон Apple iPhone 15 и вторая часть &ndash; денежная
          часть приза является неотъемлемой частью приза, которая в момент ее
          выдачи удерживается Организатором в целях выполнения функций
          налогового агента, согласно разделу 6 настоящих Правил.
        </p>
        <p>
          Получателем Главного приза становится 1 Участник за все время
          проведения Акции.
        </p>
        <p>
          За весь период проведения Акции, один Участник может получить не более
          1 (одного) приза данной категории.
        </p>
        <p>
          2.6.4. В общей сложности за весь период проведения Акции, один
          Участник может получить 1 (один) Гарантированный приз, не более 1
          (одного) Еженедельного приза на 1 одобренный чек и 1 (одного)
          Главныйприз.
        </p>
        <p>
          2.7. Призы не подлежат обмену на денежный эквивалент как полностью,
          так и частично.
        </p>
        <p>
          2.8. Внешний вид призов может отличаться от изображенных на рекламных
          материалах.
        </p>
        <p>2.9. Для участия в Акции необходимо:</p>
        <p>
          2.9.1. В Период регистрации Чеков совершить покупку от 5000 (пяти
          тысяч) рублей 00 копеек на сайте рекламодателя акции
          <a href="https://b2b.winelab.ru/">https://b2b.winelab.ru/</a>, и
          получить кассовый уникальный чек (далее &ndash; Чек) или универсальный
          передаточный документ (далее &ndash; УПД) или номера заказа (далее
          &ndash; Номер заказа) за покупку Продукции;
        </p>
        <p>2.9.2. Осуществить регистрацию Чека следующим способом:</p>
        <p>
          Для регистрации на сайте winlabe-care.ru(далее &ndash; Сайт) нужно
          заполнить форму со следующими обязательными для заполнения полями:
        </p>
        <p>&bull; Фамилия, имя и отчество</p>
        <p>&bull; Город</p>
        <p>&bull; Номер мобильного телефона</p>
        <p>&bull; E-mail (формат: ХХХ@ХХХ.ХХ)</p>
        <p>&bull; Компания</p>
        <p>&bull; ИНН</p>
        <p>
          &bull; Согласие на обработку персональных данных (обязательная
          галочка).
        </p>
        <p>
          После заполнения регистрационной формы необходимо подтвердить
          регистрацию в Акции, указав код подтверждения, полученный на указанный
          в форме регистрации номер мобильного телефона Участника.
        </p>
        <p>
          Организатор вправе отказать в участии в Акции тем пользователям, чьи
          регистрационные формы не были заполнены должным образом (была введена
          неполная или некорректная информация). После регистрации Участник не
          имеет возможности изменить мобильный телефон и E-mail, вводимые при
          регистрации в Акции.
        </p>
        <p>
          Все зарегистрированные Чеки проходят модерацию. Модерация занимает до
          5-и (пяти) рабочих дней с момента регистрации Чека или УПД или Номера
          заказа. Статус модерации Чеков отображается в Личном кабинете
          Участника.
        </p>
        <p>
          При отклонении модератором зарегистрированного Чека или УПД или Номера
          заказа Организатор отклоняет такой Чек или УПД или Номер заказа от
          участия в розыгрышах призов.
        </p>
        <p>Технические требования к Чеку или УПД:</p>
        <p>&bull; Тип файла: JPEG, PNG, JPG, BMP, PDF, HEIC</p>
        <p>&bull; Размер не более 5 Мб,</p>
        <p>&bull; разрешение не менее 200 (двести) dpi,</p>
        <p>&bull; размер по высоте и ширине до 2048px,</p>
        <p>
          &bull; копии чеков от покупки должны быть технически качественными.
        </p>
        <p>
          Не допускаются изображения, не являющиеся фотографиями (сканированные
          изображения, скриншоты, оттиски, картинки, компьютерная графика,
          фотомонтаж). В случае загрузки изображения плохого качества, модератор
          имеет право отклонить зарегистрированный Чек, УПД или Номер заказа.
        </p>
        <p>
          Перед загрузкой Чека или УПД или Номера заказа Участник должен
          убедиться, что:
        </p>
        <p>
          &bull; Дата совершения покупки на Чеке или УПД входит в
          соответствующий период, согласно пункту 1.4.2. настоящих Правил;
        </p>
        <p>
          &bull; Чек сфотографирован полностью, включая верхний и нижний края
          Чека.
          <br />
          <br /> &bull; Продукция куплена на сайте рекламодателя
          <a href="https://b2b.winelab.ru/">https://b2b.winelab.ru/</a>
        </p>
        <p>
          Все загруженные через личный кабинет Чеки или УПД или Номер заказа
          проходят модерацию. Модерация занимает до 5 (пяти) рабочих дней с
          момента регистрации Чека или УПД или Номер заказа. Статус модерации
          Чеков или УПД или Номер заказа отображается в Личном кабинете
          Участника.
        </p>
        <p>
          2.9.3. Уникальный Чек или УПД или Номер заказа принимается для участия
          в Акции только единожды. Не допускается повторная регистрация чека как
          одним Участником, так и другими Участниками. К участию в Акции
          допускается первый, кто зарегистрировал уникальный номер чека.
        </p>
        <p>
          2.9.4. Всем участникам Акции необходимо сохранить оригинал Чека или
          УПД, который был зарегистрирован, и подтверждает покупку на сайте{" "}
          <a href="https://b2b.winelab.ru/">https://b2b.winelab.ru/</a> в Период
          регистрации Чеков, УПД, Номера Заказа до окончания Срока выдачи
          призов.
        </p>
        <p>
          Идентификация Чека или УПД или Номер заказа проводится Организатором
          путем сравнения оригинала Чека и данных Чека, УПД и данных УПД, Номера
          заказа и данных Номера заказа, который был зарегистрирован.
        </p>
        <p>
          2.10. Организатор имеет право на свое собственное усмотрение, не
          объясняя Участникам причин и не вступая в переписку, признать
          недействительными любые действия участников Акции, в том числе
          заблокировать Участника, не учитывать при определении получателя
          приза, зарегистрированные чеки, а также запретить дальнейшее участие в
          Акции любому лицу, в отношении которого у Организатора возникли
          обоснованные подозрения в том, что он подделывает данные и/или
          извлекает выгоду из любой подделки данных, необходимых для участия в
          Акции в том числе, но не ограничиваясь следующими действиями:
        </p>
        <p>
          2.10.1. Если возникли обоснованные подозрения в том, что
          зарегистрированный Участником Чек или УПД или Номер заказа является
          поддельным, неверным, некорректным;
        </p>
        <p>
          2.10.2. Если возникли обоснованные подозрения в том, что
          предоставленная Участником информация при регистрации неверна,
          неполна, ошибочна или неточна;
        </p>
        <p>
          2.10.3. Если Участник действует в нарушение настоящих Правил и
          положений действующего законодательства Российской Федерации;
        </p>
        <p>
          2.11. Организатор вправе лишить звания Получателя приза лицо, не
          выполнившее требования Организатора, необходимые для вручения приза, в
          этом случае приз становится невостребованным.
        </p>
        <p>
          2.12. Во всех перечисленных случаях, когда Организатор лишает звания
          Получателя, по своему усмотрению, призы остаются невостребованными,
          если определения получателя на момент признания действия Участника
          недействительными, еще не произошло, Чеки или УПД или Номер Заказа
          таких Участников просто исключаются из участия в Акции и определение
          получателя проходит по Правилам.
        </p>
        <p>
          2.13. Организатор не участвует в спорах между Участниками о
          принадлежности регистрационных данных. Участники несут самостоятельную
          ответственность за сохранность паролей, а также за предотвращение
          доступа к Личному кабинету третьих лиц.
        </p>
        <p>
          2.15. Лицо не становится Участником Акции и не имеет право на
          получение призов, если покупка Продукции была произведена лицом ранее
          или позднее, чем в Период совершения покупки и регистрации Чеков, УПД,
          Номера Заказа, определенный в п.1.4.2. Правил.
        </p>
        <p>
          2.16. Получатели всех призов обязуются предоставить Организатору
          информацию согласно п 2.9.2. Правил;
        </p>
        <p>
          2.16.1. Получатели Еженедельного и Главного приза для получения приза
          обязуется представить Организатору следующую обязательную информацию и
          документы:
        </p>
        <p>
          &bull; сканированную копию паспорта гражданина Российской Федерации
          &ndash; разворот второй и третьей страницы (разворот с фотографией)
          полностью, и разворот с действующей регистрацией;
        </p>
        <p>
          &bull; оригинал паспорта гражданина Российской Федерации для
          подтверждения копии (при необходимости);
        </p>
        <p>
          &bull; Ф.И.О. и номер мобильного телефона, по которому представители
          Организатора могут связаться с Победителем;
        </p>
        <p>
          &bull; сканированную копию/фотографию оригинала Чека или УПД или
          Номера заказа, зарегистрированного и выигравшего в Акции;
        </p>
        <p>
          &bull; сканированную копию/фотографию своего Свидетельства ИНН, при
          наличии;
        </p>
        <p>
          &bull; иную информацию по запросу Организатора, необходимую для
          вручения призов Акции Получателями.
        </p>
        <p>
          2.17. Информация и копии документов, указанные в п. 2.16. настоящих
          Правил, должны быть представлены Организатору посредством отправки их
          по электронной почте{" "}
          <a href="mailto:support@winelab-care.ru">support@winelab-care.ru</a>в
          течение 3 (трех) рабочих дней после извещения участника о том, что он
          стал Потенциальным получателем Еженедельного приза, и в течение 5
          (пяти) рабочих дней после извещения участника о том, что он стал
          Потенциальным получателем Главного приза.
        </p>
        <p>
          Если по истечению указанного срока Получатель не предоставит
          необходимые данные, то он исключается из числа Потенциальных
          получателей призов.
        </p>
        <p>
          2.18. В случае наличия рукописного текста в любом документе или
          письме, предоставляемом Участником, такой текст должен быть написан
          четким почерком, печатными буквами.
        </p>
        <p>
          2.19. Участники, признанные Получателями призов, по просьбе
          Организатора принимают участие в интервьюировании, фото- и видеосъёмке
          в связи с признанием их обладателями соответствующих призов, без
          выплаты за это дополнительного вознаграждения, с безвозмездным
          предоставлением Организатору права на использование их фото- и
          видеоматериалов с их участием, упоминания имени, фамилии, при
          распространении рекламной информации об Акции без ограничения срока и
          территории использования. Авторские (смежные) права на полученные
          материалы принадлежат Организатору.
        </p>
        <p>
          2.20. Участник, принимая участие в настоящей Акции, соглашается с тем,
          что Организатор оставляет за собой право отказать Получателю в выдаче
          приза либо отложить (до устранения соответствующих причин невыдачи,
          если такие причины будут устранены не позднее окончания срока выдачи
          соответствующих призов в соответствии с настоящими Правилами) выдачу
          приза в следующих случаях:
        </p>
        <p>
          &bull; если Организатор не может связаться с Получателем по любым,
          независящим от Организатора причинам;
        </p>
        <p>
          &bull; если Организатору не будет предоставлен полный перечень
          документов и/или информации, указанных в настоящих Правилах;
        </p>
        <p>
          &bull; если невозможно достоверно установить, что форму заполнял
          Получатель;
        </p>
        <p>
          &bull; если информация и/или документы, указанные в пункте 2.16.
          настоящих Правил, не будут получены Организатором по любым причинам;
        </p>
        <p>
          &bull; в случае нарушения Участником Акции иных положений настоящих
          Правил, а также в иных случаях, предусмотренных действующим
          законодательством Российской Федерации.
        </p>
        <p>
          2.21. Организатор не несет ответственности за неверно указанные
          Участником сведения об электронной почте (E-mail) для начисления
          призов категории &ldquo;Еженедельный приз&rdquo;.
        </p>
        <p>
          Организатор не несет ответственности за неверно указанные Участником
          данные для передачиприза категории &ldquo;Главный приз&rdquo;.
        </p>
        <p>
          <strong>
            3. Порядок определения Получателей
            <br />
            <br />
          </strong>
          Для определения Получателей призов решением Организатора назначается
          комиссия, состоящая не менее чем из трёх человек (далее -
          &laquo;Комиссия&raquo;), из членов Комиссии решением Организатора
          назначается Председатель Комиссии.
        </p>
        <p>
          В функции членов Комиссии входит: рассмотрение претензий, разрешение
          любых спорных ситуаций во взаимоотношении Участников и Организатора,
          не урегулированных настоящими Правилами.
        </p>
        <p>
          Решение Комиссии по всем вопросам оформляется протоколом, являющимся
          неотъемлемой частью настоящих Правил, и принимается простым
          большинством голосов.
        </p>
        <p>
          Определение Получателей призов проводится Комиссией в сроки и в
          порядке, указанном ниже:
        </p>
        <p>
          3.1. Розыгрыши всех призов осуществляются только среди Чеков, УПД и
          Номера заказа, прошедших автоматическую, либо ручную модерацию,
          получивших статус Одобрен.
        </p>
        <p>
          Датой участия чека в розыгрыше является дата его одобрения
          модератором.
        </p>
        <p>3.1.1. Определение Получателей Гарантированного приза.</p>
        <p>
          Обладателем гарантированного приза - стикерпак в мессенджере Telegram
          становятся все участники зарегистрировавшиеся и успешно прошедшие тест
          (после 1 видеоурока) на сайте winelab-care.ru.
        </p>
        <p>3.1.2. Определение Получателей Еженедельного приза.</p>
        <p>
          Обладатель Еженедельного приза определяется в срок до 5 (пяти) рабочих
          дней после окончания отчетного дня среди всех Чеков, УПД и Номеров
          заказа, одобренных модератором в течение периода розыгрыша, по
        </p>
        <p>формуле:</p>
        <p>N(i) = Z*E+i,</p>
        <p>где i - порядковый номер победителя 1,2,3.</p>
        <p>
          N(i) - номер чека i-го победителя <br />
          <br /> Z - количеств чеков в розыгрыше
          <br />
          <br /> E - дробная часть числа (четыре цифры после запятой),
          являющегося курсом Евро (EUR) к рублю РФ (RUR) на день определения
          победителя <br />
          <br /> N(i) после расчета по формуле представляет из себя число с
          четырьмя знаками после запятой. Для определения номера выигравшего
          чека дробная часть этого числа отсекается.
          <br />
          <br /> Например, если значение N(i) равно 12.6789, то выигрывает чек
          номер 12. <br />
          <br /> Если рассчитанный номер больше, чем количество Чеков, УПД и
          Номеров Заказа в розыгрыше, то номер чека победителя определяется как
          остаток от деления рассчитанного номера на количество чеков.
        </p>
        <p>
          3.1.2.1. Еженедельные Периоды регистрации Чеков, УПД и Номера и кол-во
          Призов:
        </p>
        <table cellSpacing="0" cellPadding="0">
          <tbody>
            <tr>
              <td>
                <p>
                  <strong>Период регистрации Чеков, УПД, Номеров заказа</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>Количество Еженедельных призов</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>С 00:00:00 05.12.2023 по 23:59:59 17.12.2023</p>
              </td>
              <td>
                <p>
                  2 сертификата Ozon (
                  <a href="https://www.ozon.ru/">https://www.ozon.ru/</a>),1
                  сертификат Золотое яблоко (
                  <a href="https://goldapple.ru/">https://goldapple.ru/</a>)
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>С 00:00:00 05.12.2023 по 23:59:5924.12..2023</p>
              </td>
              <td>
                <p>
                  2 сертификата Ozon (
                  <a href="https://www.ozon.ru/">https://www.ozon.ru/</a>),1
                  сертификат Золотое яблоко (
                  <a href="https://goldapple.ru/">https://goldapple.ru/</a>)
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>С 00:00:00 05.12.2023 по 23:59:5931.12.2023</p>
              </td>
              <td>
                <p>
                  2 сертификата Ozon (
                  <a href="https://www.ozon.ru/">https://www.ozon.ru/</a>),1
                  сертификат Золотое яблоко (
                  <a href="https://goldapple.ru/">https://goldapple.ru/</a>)
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>3.1.3. Определение Получателя Главного приза</p>
        <p>
          Получатель Главного приза определяется единожды, в течение 5 (пяти)
          рабочих дней после окончания периода регистрации Чеков, УПД, Номера
          Заказа. Получатель приза определяется на основании Списка Чеков, УПД и
          Номеров Заказа, которые были зарегистрированы и одобрены модератором
          за весь срок, указанный в п.1.4.3., по следующей формуле: <br />
          <br /> N(i) = Z*E+i,
        </p>
        <p>
          где i - порядковый номер победителя,i=1. <br />
          <br /> N(i) - номер чека i-го победителя <br />
          <br /> Z - количеств чеков в розыгрыше
          <br />
          <br /> E - дробная часть числа (четыре цифры после запятой),
          являющегося курсом Доллара США (USD) к рублю РФ (RUR) на день
          определения победителя <br />
          <br /> N(i) после расчета по формуле представляет из себя число с
          четырьмя знаками после запятой. Для определения номера выигравшего
          чека дробная часть этого числа отсекается.
          <br />
          <br /> Например, если значение N(i) равно 12.6789, то выигрывает чек
          номер 12. <br />
          <br /> Если рассчитанный номер больше, чем количество Чеков, УПД,
          Номеров заказа в розыгрыше, то номер чека победителя определяется как
          остаток от деления рассчитанного номера на количество чеков.
        </p>
        <p>
          <br /> 3.1.3.1. Периоды регистрации чеков и кол-во Призов:
        </p>
        <table cellSpacing="0" cellPadding="0">
          <tbody>
            <tr>
              <td>
                <p>
                  <strong>Период регистрации Чеков, УПД, Номеров заказа</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>Количество Еженедельных призов</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>00:00:00 05.12.2023 по 23:59:5931.12..2023</p>
              </td>
              <td>
                <p>Apple iPhone 15</p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          3.2. В течение 5-и (пяти) рабочих дней после определения
          потенциального Получателя Еженедельного приза и в течение 5 (пяти)
          рабочих дней после определения потенциального Получателя Главного
          приза, Участник, ставший претендентом, получает e-mail-сообщение или
          звонок на номер мобильного телефона с информацией о том, что он стал
          потенциальным Получателем приза и условиями получения выигранного
          приза, в том числе уведомлением о необходимости подписания Акта о
          вручении приза.
        </p>
        <p>
          3.3. Если Еженедельный приз признан невостребованным, в том числе, но
          не ограничиваясь, Участник отказался от получения Еженедельного приза,
          либо по иным причинам, предусмотренным настоящими Правилами,
          Организатор не может/не вправе отправить Еженедельный приз такому
          Участнику в установленные правилами сроки, приз отправляется
          Участнику, Чек, УПД или Номер заказа которого зарегистрирован под
          следующим порядковым номером после определенного по формуле, указанной
          в п 3.1.2. Правил, в этом случае определение Получателя производится в
          течение 5 (пяти) рабочих дней с момента признания приза
          недействительным. Уведомление и отправка приза осуществляется в сроки,
          предусмотренные Правилами.
        </p>
        <p>
          3.4. Если Главный приз признан невостребованным, в том числе, но не
          ограничиваясь, Участник отказался от получения Главного приза, либо по
          иным причинам, предусмотренным настоящими Правилами, Организатор не
          может/не вправе отправить Главный приз такому Участнику до 20.01.2024
          года, приз отправляется Участнику, Чек, УПД или Номер заказа которого
          зарегистрирован под следующим порядковым номером после определенного
          по формуле, указанной в п 3.1.3. Правил, в этом случае определение
          Получателя производится в течение 3 рабочих дней с 20.01.2024
          включительно, уведомление и отправка приза в сроки, предусмотренные
          Правилами.
        </p>
        <p>
          3.5. Регистрация Чеков Участников, совершивших все действия,
          предусмотренные п. 2.9. настоящих Правил, происходит ежедневно в
          течение всего Периода регистрации Чеков, УПД, Номеров заказа. Статус
          регистрации каждого Чека, УПД, Номера заказа отображается в Базе
          данных (База данных &mdash; организованная в соответствии с
          определенными правилами и поддерживаемая в памяти компьютера
          совокупность данных, характеризующая актуальное состояние некоторой
          предметной области и используемая для удовлетворения информационных
          потребностей пользователей) после того, как Участник зарегистрировал
          Чек, УПД, Номер заказа.
        </p>
        <p>
          <strong>4. Порядок выдачи призов Получателям</strong>
        </p>
        <p>
          4.1. Вручение Еженедельных призов осуществляется путем отправки на
          электронный адрес Получателя, указанный им при запросе от
          Организатора, согласно пункту 2.16. Правил.
        </p>
        <p>
          Вручение приза производится при условии подписания Участником Акта о
          вручении приза и других необходимых документов, связанных с передачей
          прав на приз.
        </p>
        <p>
          4.3. Вручение Главного приза осуществляется путем отправки приза
          курьерской службой на адрес Получателя, указанный им при запросе от
          Организатора, согласно пункту 2.16. Правил.
        </p>
        <p>
          Вручение приза производится при условии подписания Участником Акта о
          вручении приза и других необходимых документов, связанных с передачей
          прав на приз.
        </p>
        <p>
          Отправка приза осуществляется в течение 10 (десяти) рабочих дней с
          даты получения всех необходимых документов от Получателя.
        </p>
        <p>
          4.4. Организатор не несет ответственности и не предоставляет
          компенсацию в случае, если Победитель не сможет воспользоваться призом
          по личным причинам.
        </p>
        <p>
          4.5. В случае отказа от подписания акта о вручении Главного приза приз
          Получателю аннулируется и признается невостребованным.
        </p>
        <p>
          4.6. Всеми невостребованными призами, включая призы, от получения
          которых Участники отказались, Организатор распоряжается по своему
          усмотрению. Призы не могут быть востребованы Участниками повторно.
        </p>
        <p>
          4.6.1. Если Получателем приза по итогам определения получателя
          становится Участник, уже получивший максимально возможное количество
          призов соответствующей категории (п. 2.6.5. Правил), то право на
          получение этого приза переходит к Участнику, Чек, УПД, Номер заказа
          которого зарегистрирован под следующим порядковым номером после
          определенного по соответствующей формуле.
        </p>
        <p>
          4.7. Призы не подлежат выдаче в какой-либо иной форме, иными
          способами, иным лицам, помимо способов, формы и лиц, описанных в
          настоящих Правилах.
        </p>
        <p>
          4.8. До получения призов, Получатель обязуется предоставить
          Организатору документы и информацию, указанные в пункте 2.16.
          настоящих Правил, а также дополнительную информацию по запросу
          Организатора. При непредоставлении Потенциальным получателем приза,
          описанных в настоящих Правилах документов и информации в срок, а также
          ином нарушении настоящий Правил, призы, на которые они могли бы
          претендовать, считаются невостребованными и не подлежат передаче
          Получателю. Организатор вправе использовать такие призы по своему
          усмотрению.
        </p>
        <p>
          <strong>5. Персональные данные</strong>
        </p>
        <p>
          5.1. Принимая участие в Акции, Участник подтверждает свое согласие на
          обработку Организатором предоставленных персональных данных, включая
          сбор, систематизацию, накопление, хранение, уточнение (обновление,
          изменение), извлечение, использование, распространение, обезличивание,
          блокирование, удаление, уничтожение для целей проведения настоящей
          Акции на весь срок ее проведения и в течение 3-х (трех) лет после её
          окончания, в соответствии с положениями, предусмотренными Федеральным
          законом РФ No 152-ФЗ от 27 июля 2006 г. &laquo;О персональных
          данных&raquo; (далее - Закон). Указанное согласие может быть отозвано
          Участником в любое время путем уведомления, направленного по
          электронной почте по адресу{" "}
          <a href="mailto:support@winelab-care.ru">support@winelab-care.ru</a>
        </p>
        <p>
          5.2. Принимая участие в Акции, Участник подтверждает свое согласие на
          обработку Рекламодателем персональных данных, указанных в пункте 2.9.
          и 2.16 настоящих Правил, включая сбор, систематизацию, накопление,
          хранение, уточнение (обновление, изменение), извлечение,
          использование, распространение, обезличивание, блокирование, удаление,
          уничтожение для целей информирования о продуктах, услугах и акциях в
          соответствии с положениями, предусмотренными Федеральным законом РФ No
          152-ФЗ от 27 июля 2006 г. &laquo;О персональных данных&raquo; (далее -
          Закон). Указанное согласие может быть отозвано Участником в любое
          время путем уведомления, направленного по электронной почте по адресу
          <a href="mailto:support@winelab-care.ru">support@winelab-care.ru</a>.
        </p>
        <p>
          5.3. В целях проведения Акции, Участник предоставляет персональные
          данные, согласно перечню, указанному в п. 2.9. и п. 2.16. настоящих
          Правил, а именно: фамилия, имя, отчество, номер мобильного телефона,
          адрес электронной почты, паспортные данные, адрес места регистрации,
          ИНН, пол, возраст, адрес электронной почты, компания (место работы) и
          т.д. Участники Акции обязуются указывать точные и актуальные
          (достоверные) данные.
        </p>
        <p>
          Принимая решение об участии в Акции, Участник тем самым подтверждает
          согласие с тем, что любая, добровольно предоставленная им информация,
          может обрабатываться Организатором, его уполномоченными
          представителями (иными лицами, привлекаемыми Организатором к
          проведению Акции, далее совместно именуемыми &laquo;иные
          партнеры&raquo;) в целях выполнения Организатором обязательств в
          соответствии с настоящими Правилами, и (или) в рекламных целях, без
          получения дополнительного согласия Участника и без уплаты ему
          какого-либо вознаграждения за это.
        </p>
        <p>
          5.4. Факт участия в Акции является свободным, конкретным,
          информированным и сознательным выражением согласия Участника на
          обработку Организатором (иными партнерами, действующим по
          поручению/заданию Организатора) персональных данных Участника
          способами, указанными в п. 5.1. Правил, необходимыми в целях
          проведения Акции, и в порядке, предусмотренном настоящими Правилами.
        </p>
        <p>
          5.5. Участники понимают и соглашаются с тем, что персональные данные,
          указанные/предоставленные ими для участия в Акции, будут
          обрабатываться Организатором (иными партнерами) всеми необходимыми
          способами в целях проведения Акции и дают согласие на такую обработку
          при соглашении с настоящими Правилами.
        </p>
        <p>
          5.6. Факт участия в Акции означает, что все ее участники соглашаются с
          настоящими Правилами, а также с тем, что их имена, фамилии и
          фотографии могут быть использованы Организатором в рекламных целях.
          Получатели призов соглашаются давать рекламные интервью об участии в
          Акции, в том числе по радио и телевидению,в иных средствах массовой
          информации, либо сниматься для изготовления графических рекламных
          материалов без уплаты за это какого-либо вознаграждения. Все авторские
          и смежные права на такие интервью будут принадлежать Организатору.
          Организатор оставляет за собой право не вступать в письменные
          переговоры либо иные контакты с участниками Акции кроме случаев,
          предусмотренных настоящими условиями.
        </p>
        <p>
          5.7. Под обработкой персональных данных в настоящих Правилах
          понимается любое действие (операция) или совокупность действий
          (операций), совершаемых с использованием средств автоматизации или без
          использования таких средств с персональными данными, включая сбор,
          запись, систематизацию, накопление, хранение, уточнение (обновление,
          изменение), извлечение, использование, передачу (распространение,
          предоставление, доступ), обезличивание, блокирование, удаление,
          уничтожение персональных данных Участников в целях проведения Акции.
        </p>
        <p>
          5.8. Под распространением персональных данных в целях настоящих Правил
          понимаются действия, направленные на раскрытие персональных данных
          неопределенному кругу лиц, а именно: открытая публикация на Сайте, а
          также в иных источниках сведений о Участнике Акции, а также о
          получении им приза.
        </p>
        <p>
          5.9. Организатор, партнеры, действующие по поручению/заданию
          Организатора, гарантируют необходимые меры защиты персональных данных
          от несанкционированного доступа. Все персональные данные, сообщенные
          Участниками для целей участия в Акции, будут храниться и
          обрабатываться Организатором, партнерами, действующими по
          поручению/заданию Организатора, в соответствии с действующим
          законодательством Российской Федерации и с соблюдением гарантий,
          указанных в настоящих Правилах.
        </p>
        <p>
          5.10. Организатор, партнеры, действующие по поручению/заданию
          Организатора, обязуются соблюдать следующие правила и предоставляют
          Участнику следующие гарантии в отношении обработки персональных
          данных:
        </p>
        <p>
          &bull; обеспечить обработку персональных данных с соблюдением всех
          применимых требований законодательства Российской Федерации в области
          защиты персональных данных, в том числе с соблюдением принципов,
          требований, обязательств оператора персональных данных, установленных
          Законом;
        </p>
        <p>
          &bull; обрабатывать персональные данные только в объеме и в целях
          проведения Акции, а также в рекламных целях. Использование и иные виды
          обработки персональных данных в целях информирования субъектов
          персональных данных о каких-либо продуктах и услугах, а также в любых
          иных целях допускается только в объеме и в случаях, предусмотренных
          Законом;
        </p>
        <p>
          &bull; в случае, если Организатор в целях исполнения своих
          обязательств перед Участниками Акции должны передать или иным образом
          раскрыть персональные данные Участников Акции третьим лицам,
          осуществлять указанные действия с соблюдением требований Закона;
        </p>
        <p>
          &bull; нести ответственность за охрану и обеспечение безопасности и
          конфиденциальности персональных данных Участников Акции при их
          обработке в соответствии с требованиями законодательства РФ.
        </p>
        <p>
          5.11. Отзыв Участником и/или иным субъектом персональных данных, чьи
          персональные данные были предоставлены Участником Акции Организатору
          (или его представителем), согласия на обработку персональных данных
          автоматически влечет за собой выход соответствующего Участника из
          участия в Акции и делает невозможным получение приза. После получения
          уведомления Участника и/или иного субъекта персональных данных, чьи
          персональные данные были предоставлены Участником Акции Организатору
          (или его представителя), об отзыве согласия на обработку персональных
          данных, Организатор обязан прекратить их обработку и обеспечить
          прекращение такой обработки лицом, действующим по поручению/заданию
          Организатора и в случае, если сохранение персональных данных более не
          требуется для целей обработки персональных данных, уничтожить
          персональные данные или обеспечить их уничтожение (если обработка
          персональных данных осуществляется другим лицом, действующим по
          поручению/заданию Организатора) в срок, не превышающий 90 (девяносто)
          дней с даты поступления указанного отзыва, за исключением случаев,
          когда Организатор вправе осуществлять обработку персональных данных
          без согласия субъекта персональных данных на основаниях,
          предусмотренных Законом или другими федеральными законами. Под
          &laquo;Участником&raquo; в настоящем пункте Правил понимаются все
          лица, предоставившие персональные данные Организатору в целях участия
          в Акции согласно настоящим Правилам. Трансграничная передача
          персональных данных Организатором не осуществляется.
        </p>
        <p>
          Участник имеет право на доступ к данным о себе и/или информации о том,
          кто и в каких целях использует или использовал его персональные
          данные. Для реализации права на доступ и иных указанных выше прав
          Участник вправе связаться с Организатором по адресу:630099,
          Новосибирская обл., г.Новосибирск, ул. Ядринцевская, д. 54 этаж 1 офис
          4 или по электронной почте по адресуsupport@winelab-care.ru
        </p>
        <p>
          <strong>6. Прочее</strong>
        </p>
        <p>
          6.1. Организатор выступает в качестве налогового агента и обязуется
          перечислить в бюджет РФ налог на доходы физических лиц согласно нормам
          налогового законодательства РФ, который взимается от стоимости любых
          призов, получаемых в проводимых конкурсах, играх и других мероприятиях
          в целях рекламы товаров, работ и услуг в части превышения размеров,
          указанных в п. 28 ст. 217 НК РФ (4000 руб.) по ставке 35 % от общей
          стоимости приза за счет денежной части приза.
        </p>
        <p>
          Получатель согласен на удержание и перечисление НДФЛ в полном размере
          (100% от денежной части), без учета ограничения, предусмотренного абз.
          2 ч. 4 ст. 226 НК РФ. Организатор обязуется предоставить в налоговые
          органы информацию о доходе, полученном Победителями Акции в результате
          вручения им призов.
        </p>
        <p>
          6.2. Согласно законодательству РФ, не облагаются налогом на доходы
          физических лиц (НДФЛ) доходы, не превышающие в совокупности 4000
          рублей, полученные за налоговый период (календарный год) от
          организаций, в т.ч., в виде призов, выигрышей или подарков в
          проводимых конкурсах, играх и других мероприятиях в целях рекламы
          товаров (работ, услуг) (п. 28 ст. 217 НК РФ).
        </p>
        <p>
          6.3. Участник Акции может в любой момент, до фактического приема приза
          и/или получения приза в случае с Гарантированными призом,
          Еженедельными и Ежедневными призами, отказаться от участия в Акции,
          направив соответствующее заявление Организатору заказным почтовым
          отправлением. Заявление составляется в свободной форме и должно
          содержать ФИО Участника, серию и номер документа, удостоверяющего
          личность и номер контактного телефона, в этом случае приз становится
          невостребованным.
        </p>
        <p>
          6.4. Организатор имеет право изменить Правила Акции в любой момент,
          разместив соответствующую информацию на сайте winelab-care.ru Во всем,
          что не предусмотрено настоящими Правилами, Организатор и Участники
          Акции руководствуются действующим законодательством Российской
          Федерации.
        </p>
      </div>
    </div>
    <Footer />
  </>
);
