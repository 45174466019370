import { PayloadMetaAction } from 'typesafe-actions'
import { Action, AnyAction } from 'redux'
import { createDispatchHook, ReactReduxContextValue } from 'react-redux'
import { Context, Dispatch, useCallback } from 'react'

class OperationIdHandler {
  private currentNumber = 0
  getOperationId = (action: Action) => {
    const num = this.currentNumber
    this.currentNumber = this.currentNumber + 1
    return `${num}_${action.type}`
  }
}
export const operationIdHandler = new OperationIdHandler()

export function createUseCustomDispatch<
  S = any,
  A extends PayloadMetaAction<any, any, any> = any,
>(context?: Context<ReactReduxContextValue<S, A>>) {
  const useDispatch = createDispatchHook<S, A>(context)
  return function useCustomDispatch() {
    const reduxDispatch = useDispatch()
    const dispatch = useCallback(
      function (action: A) {
        const id = action.meta || operationIdHandler.getOperationId(action)
        action.meta = id
        reduxDispatch(action)
        return id
      },
      [reduxDispatch],
    )
    return dispatch
  }
}
