import axios from "axios";
import store from "../store";

import { BASE_PATH, TOKEN_PREFIX } from "./constants";
import { Api } from "./api";
import { userLogoutAction } from "../store/user/action";

import {
  catchResponseError,
  placeTokenIntoRequests,
} from "../libraries/packages/api-interceptors/src";

export const axiosInstance = axios.create({ baseURL: BASE_PATH });

placeTokenIntoRequests(
  axiosInstance,
  () => store.getState().token.data || "",
  "Authorization",
  TOKEN_PREFIX
);

catchResponseError(
  axiosInstance,
  () => {
    store.dispatch(userLogoutAction.request([], "401"));
  },
  401
);

export const api = new Api(axiosInstance);
