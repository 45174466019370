import { useUser } from "../../utils/useUser";
import { Video } from "../../components/video";
import styles from "./testing.module.scss";
import { CommonButton } from "../../components/commonButton";
import { useLocation, useNavigate } from "react-router-dom";
import useUniqueDispatch, { useSelector } from "../../utils/redux-utils";
import { testsGetTestListAction } from "../../store/tests/action";
import { RootState } from "../../store/root-reducer";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { TestType } from "../../api/models/testType";
import { BASE_PATH } from "../../api/constants";
import { TestForm } from "../../components/tests/testForm";

const TestElement = ({
  onTestSelect,
  test,
}: {
  onTestSelect: Dispatch<SetStateAction<number | null>>;
  test: TestType;
}) => {
  const previewUrl = BASE_PATH + test.preview.formats.small.url;
  const videoUrl = BASE_PATH + test.video.url;
  const isReceiptLoaded = test.available;

  const location = useLocation();
  const navigate = useNavigate();
  const contentRestrictionRoute = () =>
    navigate("/upload-receipt?test_id=" + test.id, {
      state: { mainLocation: location },
    });

  return (
    <div className={styles.testContainer}>
      <div className={styles.testContainer__bottom}>
        <Video
          previewUrl={previewUrl}
          videoUrl={videoUrl}
          locked={!test.open}
        />
        <div className={styles.testDescription}>{test.title}</div>
      </div>
      <CommonButton
        title={isReceiptLoaded ? "Пройти тест" : "Загрузить чек"}
        white
        onClick={
          isReceiptLoaded
            ? () => onTestSelect(test.id)
            : contentRestrictionRoute
        }
      />
    </div>
  );
};

const TopTestElement = ({
  onTestSelect,
  test,
}: {
  onTestSelect: Dispatch<SetStateAction<number | null>>;
  test: TestType;
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const contentRestrictionRoute = () =>
    navigate("/upload-receipt?test_id=" + test.id, {
      state: { mainLocation: location },
    });

  const previewUrl = BASE_PATH + test.preview.formats.small.url;
  const videoUrl = BASE_PATH + test.video.url;

  const isReceiptLoaded = test.available;

  return (
    <>
      <div className={styles.testDescription}>{test.title}</div>
      <div className={styles.topTest}>
        <div style={{ flex: 2 }}>
          <Video
            previewUrl={previewUrl}
            videoUrl={videoUrl}
            locked={!test.open}
          />
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div className={styles.testDescription}>
            За успешное прохождение первого теста ты получишь награду в виде
            уникального стикерпака для офис-менеджеров
          </div>
          <CommonButton
            title={isReceiptLoaded ? "Пройти тест" : "Загрузить чек"}
            white
            onClick={
              isReceiptLoaded
                ? () => onTestSelect(test.id)
                : contentRestrictionRoute
            }
          />
        </div>
      </div>
    </>
  );
};

export const Testing = () => {
  const { firstName } = useUser();

  const testList = useSelector((store: RootState) => store.tests.data);

  const [getTestsToken, getTests] = useUniqueDispatch(
    testsGetTestListAction.request
  );

  useEffect(() => {
    getTests([]);
  }, []);

  const [selectedTestId, setSelectedTestId] = useState<number | null>(null);

  const selectedTest = useMemo(
    () =>
      (testList &&
        typeof selectedTestId === "number" &&
        testList.find((_test) => _test.id === selectedTestId)) ||
      null,
    [selectedTestId]
  );

  return (
    <div className={styles.content}>
      <h2 className={styles.blockHeader}>
        Привет,<span style={{ letterSpacing: "0.2em" }}> </span>
        {firstName}!
      </h2>
      <div className={styles.subtitle}>
        Смотрите видеогайды и проходите тесты
      </div>
      {testList && Boolean(testList?.length) && (
        <>
          <TopTestElement test={testList[0]} onTestSelect={setSelectedTestId} />
          {selectedTest && <TestForm test={selectedTest} />}
          <div className={styles.tests}>
            <TestElement test={testList[1]} onTestSelect={setSelectedTestId} />
            <TestElement test={testList[2]} onTestSelect={setSelectedTestId} />
            <TestElement test={testList[3]} onTestSelect={setSelectedTestId} />
          </div>
        </>
      )}
    </div>
  );
};
