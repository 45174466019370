import React, { useEffect } from "react";
import { Header } from "../../components/header/header";
import { useUser } from "../../utils/useUser";
import { Footer } from "../../components/footer/footer";
import styles from "./index.module.scss";
import { NavLink, Outlet, useNavigate } from "react-router-dom";

const ProfileMenu = () => {
  const { logout, isLoggedIn } = useUser();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) navigate("/");
  }, [isLoggedIn]);
  return (
    <nav className={styles.links}>
      <NavLink
        to="account"
        className={({ isActive }) =>
          [styles.link, isActive ? styles.active : ""].join(" ")
        }
      >
        Персональные данные
      </NavLink>
      <NavLink
        to="receipts"
        className={({ isActive }) =>
          [styles.link, isActive ? styles.active : ""].join(" ")
        }
      >
        Чеки
      </NavLink>

      <a href="#" onClick={logout} className={styles.logout}>
        Выйти
      </a>
    </nav>
  );
};

export const ProfileScreen = () => (
  <>
    <Header />
    <div className={styles.container}>
      <ProfileMenu />
      <div className={styles.outlet}>
        <Outlet />
      </div>
    </div>

    <Footer />
  </>
);
