import {
  userLoadAction,
  userLoginAction,
  userLogoutAction,
  userUpdateAction,
} from "./action";
import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import api from "../storeConfig";
import { RequestState } from "../../libraries/packages/redux-utils/src/request/create-request-reducer";
import createLoadingReducer from "../../libraries/packages/redux-utils/src/request/create-loading-reducer";
import createOperationReducer from "../../libraries/packages/redux-utils/src/request/create-operation-reducer";
import createErrorReducer from "../../libraries/packages/redux-utils/src/request/create-error-reducer";

export const userReducer = combineReducers<RequestState<typeof api.user.type>>({
  data: createReducer(null).handleAction(
    [
      userLoginAction.success,
      userLogoutAction.success,
      userLoadAction.success,
      userUpdateAction.success,
    ],
    (state, action) => action.payload
  ),
  isLoading: createLoadingReducer(
    userLoginAction,
    userLogoutAction,
    userLoadAction,
    userUpdateAction
  ),
  lastOpId: createOperationReducer(
    userLoginAction,
    userLogoutAction,
    userLoadAction,
    userUpdateAction
  ),
  error: createErrorReducer(
    userLoginAction,
    userLogoutAction,
    userLoadAction,
    userUpdateAction
  ),
});
