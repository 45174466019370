import {
  receiptsGetReceiptsListAction,
  receiptsPostReceiptAction,
} from "./action";
import api from "../storeConfig";
import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import createLoadingReducer from "../../libraries/packages/redux-utils/src/request/create-loading-reducer";
import createOperationReducer from "../../libraries/packages/redux-utils/src/request/create-operation-reducer";
import createErrorReducer from "../../libraries/packages/redux-utils/src/request/create-error-reducer";
import { RequestState } from "../../libraries/packages/redux-utils/src/request/create-request-reducer";

export const receiptsReducer = combineReducers<
  RequestState<typeof api.receipts.type>
>({
  data: createReducer(null)
    .handleAction(
      [receiptsGetReceiptsListAction.success],
      (state, action) => action.payload
    )
    .handleAction([receiptsPostReceiptAction.success], (state, action) => {
      state ||= [];
      state.push(action.payload);
      return state;
    }),
  isLoading: createLoadingReducer(
    receiptsGetReceiptsListAction,
    receiptsPostReceiptAction
  ),
  lastOpId: createOperationReducer(
    receiptsGetReceiptsListAction,
    receiptsPostReceiptAction
  ),
  error: createErrorReducer(
    receiptsGetReceiptsListAction,
    receiptsPostReceiptAction
  ),
});
