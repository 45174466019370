import React, { useEffect, useState } from "react";
import { ModalWrapper } from "../../components/modalWrapper/modalWrapper";
import {
  ModalHeader,
  ModalText,
} from "../../components/modalTypography/modalTypography";
import { useLocation, useNavigate } from "react-router-dom";
import { CommonButton } from "../../components/commonButton";
import { CONTENT_RESTRICTION_STORAGE_KEY } from "../../api/constants";
import styles from "./modals.module.scss";

const isOver18 = () =>
  localStorage.getItem(CONTENT_RESTRICTION_STORAGE_KEY) === "true";

const saveConfirmation = () =>
  localStorage.setItem(CONTENT_RESTRICTION_STORAGE_KEY, "true");

export const useContentRestriction = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isOver18()) {
      navigate("/content-restriction", {
        state: { mainLocation: location, noAnim: true },
      });
    }
  }, []);
};

export const ContentRestrictionScreen = () => {
  const navigate = useNavigate();
  return (
    <ModalWrapper>
      <ModalHeader>Ограничение контента</ModalHeader>
      <div className={styles.layout}>
        <ModalText style={{ flex: 1, marginTop: 12 }}>
          Контент содержит информацию, доступную для просмотра лицам, достигшим
          совершеннолетнего возраста (18+)
        </ModalText>
        <div className={styles.controls}>
          <CommonButton
            title="Мне исполнилось 18 лет"
            white
            onClick={() => {
              saveConfirmation();
              navigate("/");
            }}
          />
          <CommonButton
            title="Мне меньше 18 лет"
            onClick={() => {
              // @ts-ignore
              window.location = "https://google.com";
            }}
          />
        </div>
      </div>
    </ModalWrapper>
  );
};
