import { useMemo } from "react";
import styles from "./index.module.scss";

export type TableData<T extends Record<string, any>> = {
  renderers?: Partial<Renderers<T>>;
  headers: Partial<Record<keyof T, string>>;
  data: T[];
};
type Renderers<T extends Record<string, any>> = {
  [Key in keyof T]: (val: T[Key]) => string;
};
export type ColumnData<T extends Record<string, any>> = {
  header: keyof T;
  renderers: Partial<Renderers<T>>;
  headers: Partial<Record<keyof T, string>>;
  data: T[];
};

const Column = <T extends Record<string, any>>({
  header,
  renderers,
  headers,
  data,
}: ColumnData<T>) => (
  <div className={styles.column}>
    <div className={styles.header}>{headers[header]}</div>
    {data.map((el) => (
      <div>{(renderers[header] || String)(el[header])}</div>
    ))}
  </div>
);

export const Table = <T extends Record<string, any>>({
  headers,
  renderers = {},
  data,
}: TableData<T>) => {
  const _headers = useMemo(
    () => Object.keys(headers) as (keyof T)[],
    [headers]
  );
  return (
    <div className={styles.container}>
      {_headers.map((header) => (
        <Column
          key={header as string}
          header={header}
          data={data}
          headers={headers}
          renderers={renderers}
        />
      ))}
    </div>
  );
};
