import {
  testsGetTestListAction,
  testsGetResultListAction,
  testsGetTestAction,
  testsPostTestResultAction,
} from "./action";
import api from "../storeConfig";
import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import createLoadingReducer from "../../libraries/packages/redux-utils/src/request/create-loading-reducer";
import createOperationReducer from "../../libraries/packages/redux-utils/src/request/create-operation-reducer";
import createErrorReducer from "../../libraries/packages/redux-utils/src/request/create-error-reducer";
import { RequestState } from "../../libraries/packages/redux-utils/src/request/create-request-reducer";

export const testsReducer = combineReducers<
  RequestState<typeof api.tests.type>
>({
  data: createReducer([]).handleAction(
    [testsGetTestListAction.success],
    (state, action) => action.payload
  ),
  isLoading: createLoadingReducer(
    testsGetTestListAction,
    testsGetResultListAction,
    testsGetTestAction,
    testsPostTestResultAction
  ),
  lastOpId: createOperationReducer(
    testsGetTestListAction,
    testsGetResultListAction,
    testsGetTestAction,
    testsPostTestResultAction
  ),
  error: createErrorReducer(
    testsGetTestListAction,
    testsGetResultListAction,
    testsGetTestAction,
    testsPostTestResultAction
  ),
});
