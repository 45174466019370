import api from "../storeConfig";
import { createAsyncActionWithMeta } from "../../libraries/packages/redux-utils/src/request/action";

export const tokenLoadTokenAction = createAsyncActionWithMeta(
  "token_tokenLoadTokenAction_REQUEST",
  "token_tokenLoadTokenAction_SUCCESS",
  "token_tokenLoadTokenAction_FAILURE"
)<
  Parameters<typeof api.token.methods.loadToken>,
  typeof api.token.type,
  Error
>();

export const tokenSetTokenAction = createAsyncActionWithMeta(
  "token_tokenSetTokenAction_REQUEST",
  "token_tokenSetTokenAction_SUCCESS",
  "token_tokenSetTokenAction_FAILURE"
)<
  Parameters<typeof api.token.methods.setToken>,
  typeof api.token.type,
  Error
>();
