import { useUser } from "../../utils/useUser";
import styles from "./index.module.scss";
import { useState } from "react";
import { registerStateDefault, RegistrationDataType } from "../auth/register";
import { UserDataForm } from "../../components/userDataForm";
import useUniqueDispatch, {
  isLoading,
  useSelector,
} from "../../utils/redux-utils";
import { userUpdateAction } from "../../store/user/action";
import { RootState } from "../../store/root-reducer";
import _ from "lodash";

export const AccountScreen = () => {
  const { user } = useUser();

  const [userData, setUserData] = useState<RegistrationDataType>({
    ..._.pick(user!, [
      "firstname",
      "lastname",
      "inn",
      "company",
      "email",
      "phone",
    ]),
  });

  const [updateToken, update] = useUniqueDispatch(userUpdateAction.request);

  const updateHandler = () => {
    update([{ user: userData }]);
  };

  const userRequest = useSelector((store: RootState) => store.user);

  const isUpdating = isLoading(userRequest, updateToken);

  return (
    <div>
      <h1 className={styles.header}>Персональные данные</h1>
      <UserDataForm
        disablePhone
        userData={userData}
        setUserData={setUserData}
        onSend={updateHandler}
        isLoading={isUpdating}
        sendButtonLabel={"Сохранить"}
      />
    </div>
  );
};
