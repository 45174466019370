import { createUseCustomDispatch } from './useCustomDispatch'
import { Context, useCallback, useEffect, useRef, useState } from 'react'
import Action from './action'
import { RequestState } from './create-request-reducer'
import { ReactReduxContextValue } from 'react-redux'
import { PayloadMetaAction } from 'typesafe-actions'

export default function createUseUniqueDispatch<
  S = any,
  A extends PayloadMetaAction<string, any, string | undefined> = any,
>(context?: Context<ReactReduxContextValue<S, A>>) {
  const useCustomDispatch = createUseCustomDispatch(context)

  return function useUniqueDispatch<Type>(
    actionHandler: Action<[Type, string | undefined]>,
  ): [string | null, (args: Type, uniqueToken?: string) => void, () => void] {
    const dispatch = useCustomDispatch()
    const uniqueTokenRef = useRef<string | null>(null)
    const [trigger, setTrigger] = useState(false)

    const actionDispatch = useCallback(
      (args: Type, uniqueToken?: string) => {
        // @ts-ignore fixme: incompatible typings
        uniqueTokenRef.current = dispatch(actionHandler(args, uniqueToken))
        setTrigger((state) => !state)
      },
      [dispatch],
    )
    const reset = useCallback(() => {
      uniqueTokenRef.current = null
    }, [])

    return [uniqueTokenRef.current, actionDispatch, reset]
  }
}

export function isData<DataType, ErrorType extends Error>(
  state: RequestState<DataType, ErrorType>,
  requestToken: string | null,
): DataType | null | undefined {
  return state.lastOpId !== null &&
    requestToken !== null &&
    state.lastOpId === requestToken &&
    !state.error
    ? state.data
    : null
}

export function isError<DataType, ErrorType extends Error>(
  state: RequestState<DataType, ErrorType>,
  requestToken: string | null,
): ErrorType | null | undefined {
  return state.lastOpId !== null &&
    requestToken !== null &&
    state.lastOpId === requestToken
    ? state.error
    : null
}

export function isLoading<DataType, ErrorType extends Error>(
  state: RequestState<DataType, ErrorType>,
  requestToken: string | null,
  defaultLoading?: boolean, //fixme maybe move default loading to hook useLoading?
  //fixme Consider way to start loading content before first component render
): boolean {
  return (
    (requestToken === null && defaultLoading) ||
    (requestToken !== null && !!state.isLoading.has(requestToken))
  )
}
