import api from "../storeConfig";
import { createAsyncActionWithMeta } from "../../libraries/packages/redux-utils/src/request/action";

export const userLoginAction = createAsyncActionWithMeta(
  "user_userLoginAction_REQUEST",
  "user_userLoginAction_SUCCESS",
  "user_userLoginAction_FAILURE"
)<
  Parameters<typeof api.user.methods.login>,
  { user: typeof api.user.type; token: string },
  Error
>();

export const userLogoutAction = createAsyncActionWithMeta(
  "user_userLogoutAction_REQUEST",
  "user_userLogoutAction_SUCCESS",
  "user_userLogoutAction_FAILURE"
)<Parameters<typeof api.user.methods.logout>, any, Error>();

export const userLoadAction = createAsyncActionWithMeta(
  "user_userLoadAction_REQUEST",
  "user_userLoadAction_SUCCESS",
  "user_userLoadAction_FAILURE"
)<Parameters<typeof api.user.methods.load>, typeof api.user.type, Error>();

export const userUpdateAction = createAsyncActionWithMeta(
  "user_userUpdateAction_REQUEST",
  "user_userUpdateAction_SUCCESS",
  "user_userUpdateAction_FAILURE"
)<Parameters<typeof api.user.methods.update>, typeof api.user.type, Error>();
