import AsyncAction, { AsyncActionWithCancel } from './async-action'
import { createReducer } from 'typesafe-actions'

export default function createErrorReducer(
  ...actions: (
    | AsyncAction<any, any, any, any, any, any>
    | AsyncActionWithCancel<any, any, any, any, any, any, any, any>
  )[]
) {
  return createReducer<Error | null>(null)
    .handleAction(
      actions.map((act) => act.request),
      (state, action) => {
        return null
      },
    )
    .handleAction(
      actions.map((act) => act.failure),
      (state, action) => {
        return action.payload
      },
    )
}
