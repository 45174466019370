import api from "../storeConfig";
import { all, call, put, takeEvery } from "redux-saga/effects";
import {
  receiptsGetReceiptsListAction,
  receiptsPostReceiptAction,
} from "./action";

export const getReceiptsListRequest = function* (
  action: ReturnType<typeof receiptsGetReceiptsListAction.request>
) {
  try {
    let response = yield call(() =>
      api.receipts.methods.getReceiptsList(...action.payload)
    );
    yield put(
      receiptsGetReceiptsListAction.success(response.data, action.meta)
    );
  } catch (error) {
    yield put(receiptsGetReceiptsListAction.failure(error, action.meta));
  }
};

export const postReceiptRequest = function* (
  action: ReturnType<typeof receiptsPostReceiptAction.request>
) {
  try {
    const formData = new FormData();
    const updateData = { ...action?.payload?.[0] };

    formData.append("files", updateData.file);
    formData.append("test", updateData.testId);
    formData.append("order_number", updateData.orderNumber);

    let response = yield call(() => api.receipts.methods.postReceipt(formData));
    // @ts-ignore
    window.ym(95762061, "reachGoal", "load_check");
    yield put(receiptsPostReceiptAction.success(response.data, action.meta));
  } catch (error) {
    yield put(receiptsPostReceiptAction.failure(error, action.meta));
  }
};

export const receiptsSaga = function* () {
  yield all([
    takeEvery(receiptsGetReceiptsListAction.request, getReceiptsListRequest),
    takeEvery(receiptsPostReceiptAction.request, postReceiptRequest),
  ]);
};
