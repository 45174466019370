import { TestType } from "../../api/models/testType";
import { useRef, useState } from "react";
import useUniqueDispatch, {
  isLoading,
  useSelector,
} from "../../utils/redux-utils";
import { testsPostTestResultAction } from "../../store/tests/action";
import { RootState } from "../../store/root-reducer";
import styles from "./index.module.scss";
import { CommonButton } from "../commonButton";
import { ButtonWrapper } from "../buttonWrapper";

const ALPHABET = "abcdefghijklmnopqrstuvw";

export const TestForm = ({ test }: { test: TestType }) => {
  const formRef = useRef();
  const [isLocked, setIsLocked] = useState(false);

  const [postAnswerToken, postAnswer] = useUniqueDispatch(
    testsPostTestResultAction.request
  );

  const testRequest = useSelector((store: RootState) => store.tests);

  const isSending = isLoading(testRequest, postAnswerToken);

  const submitHandler = () => {
    const formData = new FormData(formRef.current);
    const answers = {
      test: {
        id: test.id,
        questions: Object.entries(Object.fromEntries(formData)).map(
          ([questionId, answerId]) => ({
            id: questionId,
            answer_id: answerId,
          })
        ),
      },
    };

    postAnswer([{ data: answers }]);
    // fixme: validate that the request actually went out
    setIsLocked(true);
  };
  return (
    <div className={styles.form}>
      <h1>{test.title}</h1>

      <form ref={formRef}>
        {test.questions.map((_question, i) => (
          <fieldset>
            <legend>
              {i + 1}. {_question.text}
            </legend>
            {_question.answers.map((_answer, index) => (
              <>
                <input
                  type="radio"
                  id={`${_question.id}_${_answer.id}`}
                  name={_question.id.toString()}
                  value={_answer.id}
                  onChange={() => setIsLocked(false)}
                />
                <label
                  htmlFor={`${_question.id}_${_answer.id}`}
                  className={
                    isLocked && _answer.is_right ? styles.correctAnswer : ""
                  }
                >
                  <div className={styles.testLetter}>{ALPHABET[index]}.</div>
                  {_answer.text}
                </label>
                <br />
              </>
            ))}
          </fieldset>
        ))}
      </form>
      <ButtonWrapper>
        <CommonButton title="Проверить" onClick={submitHandler} white />
      </ButtonWrapper>
    </div>
  );
};
