import styles from "./index.module.scss";
// @ts-ignore fixme: contribute types
import Faq from "react-faq-component";

const QUESTIONS = [
  {
    title: "Кто может участвовать в программе лояльности?",
    content: "Принять участие в программе может офис-менеджер любой компании.",
  },
  {
    title: "Что нужно сделать, чтобы открыть следующий видеоролик?",
    content:
      "Приобрести продукцию на сайте https://b2b.winelab.ru от 5 000 тысяч рублей, в период с 5 декабря 2023 года по 31 декабря 2023 года. Зарегистрироваться на данном сайте и загрузить чек. После проверки чека, следующий видеоурок  станет доступен в вашем личном кабинете.",
  },
  {
    title: "Какие чеки принимаются для участия в розыгрыше?",
    content: `В розыгрыше участвуют чеки от 5 000 рублей за продукцию, приобретенную на сайте https://b2b.winelab.ru, в период с 5 декабря 2023 года по 31 декабря 2023 года.
    Чек можно зарегистрировать путем загрузки файла.
      Зарегистрированные чеки проходят проверку в Федеральной налоговой службе Российской Федерации, а также проверку на соответствие условиям розыгрыша. После успешного прохождения данных проверок чек принимается для участия в розыгрыше.`,
  },
  {
    title: "Как определяются победители розыгрыша?",
    content:
      "Чтобы принять участие в розыгрыше, нужно загрузить чек посмотреть видеоурок и успешно пройти тест. Победившие чеки рассчитываются по формуле Z*E+i, где Z - количество чеков в розыгрыше, E - дробная часть курса валюты на заданную дату, указанную в правилах, i - порядковый номер победителя.",
  },
  {
    title: "Как я узнаю о том, что получил приз?",
    content:
      'Если вы станете победителем розыгрыша, мы уведомим вас посредством электронной почты, указанной вами при регистрации. Также информация о победителях будет доступна на сайте в разделе "Победители". Убедитесь, что ваша контактная информация в личном кабинете актуальна.',
  },
];

const LeArrow = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.14286 0L6.85714 0V6.85714H0L0 9.14286H6.85714V16H9.14286V9.14286H16V6.85714H9.14286V0Z"
      fill="#231F20"
    />
  </svg>
);

export const FAQ = () => {
  return (
    <div className={styles.container}>
      <Faq
        data={{ rows: QUESTIONS }}
        config={{
          arrowIcon: LeArrow,
        }}
      />
    </div>
  );
};
