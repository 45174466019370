import React, { useCallback, useState } from "react";
import styles from "../../screens/auth/index.module.scss";
import { CommonTextInput } from "../TextInput/commonTextInput";
import { CustomPhoneInput } from "../TextInput/phoneInput";
import { ModalText } from "../modalTypography/modalTypography";
import { CommonButton } from "../commonButton";
import { RegistrationDataType } from "../../screens/auth/register";

function checkInnShort(value: string) {
  if (
    typeof value !== "string" ||
    (value.length !== 10 && value.length !== 12) ||
    value.split("").some((symbol) => isNaN(Number(symbol)))
  )
    return false;

  if (value.length === 10) {
    return (
      Number(value[9]) ===
      (value
        .split("")
        .slice(0, -1)
        .reduce(
          (summ, symbol, index) =>
            [2, 4, 10, 3, 5, 9, 4, 6, 8][index] * Number(symbol) + summ,
          0
        ) %
        11) %
        10
    );
  } else if (value.length === 12) {
    let checkSumOne =
      (value
        .split("")
        .slice(0, -2)
        .reduce(
          (summ, symbol, index) =>
            [7, 2, 4, 10, 3, 5, 9, 4, 6, 8][index] * Number(symbol) + summ,
          0
        ) %
        11) %
      10;

    let checkSumTwo =
      (value
        .split("")
        .slice(0, -1)
        .reduce(
          (summ, symbol, index) =>
            [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8][index] * Number(symbol) + summ,
          0
        ) %
        11) %
      10;

    return (
      checkSumOne === Number(value[10]) && checkSumTwo === Number(value[11])
    );
  }
}

const VALIDATORS = {
  firstname: (value: string) => {
    if (!value) return "Имя не может быть пустым";
    if (!/^[A-Za-zА-Яа-я ]+$/g.test(value))
      return "Имя может содержать только буквы";
    return "";
  },
  lastname: (value: string) => {
    if (!value) return "Фамилия не может быть пустой";
    if (!/^[A-Za-zА-Яа-я ]+$/g.test(value))
      return "Фамилия может содержать только буквы";
    return "";
  },
  inn: (value: string) => {
    if (!value) return "ИНН не может быть пустым";
    if (!checkInnShort(value)) return "Некорректный ИНН";
    return "";
  },
  company: (value: string) => {
    if (!value) return "Название компании не может быть пустым";
    return "";
  },
  email: (value: string) => {
    if (!value) return "Адрес почты требуется для рассылок";
    if (!value.includes("@"))
      return "Адрес не соответствует формату ХХХ@ХХХ.ХХ";
    return "";
  },
  phone: (value: string) => {
    if (!value) return "Номер телефона требуется для авторизации";
    return "";
  },
};
const EMPTY_ERROR_STATE = {
  firstname: "",
  lastname: "",
  inn: "",
  company: "",
  email: "",
  phone: "",
};

export const UserDataForm = ({
  userData,
  setUserData,
  onSend,
  isLoading,
  sendButtonLabel,
  description,
  disablePhone = false,
}: {
  sendButtonLabel?: string;
  isLoading?: boolean;
  onSend: () => void;
  description?: string;
  disablePhone?: boolean;
  userData: RegistrationDataType;
  setUserData: React.Dispatch<React.SetStateAction<RegistrationDataType>>;
}) => {
  const [errors, setErrors] = useState({ ...EMPTY_ERROR_STATE });
  const setValue = useCallback(
    (key: keyof RegistrationDataType, value: string) => {
      setUserData((prevState) => ({
        ...prevState,
        [key]: value,
      }));

      setErrors({ ...errors, [key]: "" });
    },
    [errors]
  );

  const validateAndSend = useCallback(() => {
    const newErrors = { ...EMPTY_ERROR_STATE };
    let hasErrors = false;
    for (const [key, value] of Object.entries(userData)) {
      // @ts-ignore
      newErrors[key] = VALIDATORS[key](value);
      // @ts-ignore
      if (newErrors[key]) {
        hasErrors = true;
      }
    }
    if (!hasErrors) onSend();
    setErrors(newErrors);
  }, [userData, onSend]);

  return (
    <div className={styles.center}>
      <div className={styles.wideContent}>
        <div className={styles.registerRow}>
          <CommonTextInput
            value={userData.firstname}
            error={errors.firstname}
            onChange={(event) => setValue("firstname", event.target.value)}
            label="Имя"
            style={{ flex: 1 }}
          />
          <CommonTextInput
            value={userData.lastname}
            error={errors.lastname}
            onChange={(event) => setValue("lastname", event.target.value)}
            label="Фамилия"
            style={{ flex: 1 }}
          />
        </div>
        <div className={styles.registerRow}>
          <CommonTextInput
            value={userData.inn}
            error={errors.inn}
            onChange={(event) => setValue("inn", event.target.value)}
            label="ИНН"
            style={{ flex: 1 }}
          />
          <CommonTextInput
            value={userData.company}
            error={errors.company}
            onChange={(event) => setValue("company", event.target.value)}
            label="Компания"
            style={{ flex: 1 }}
          />
        </div>
        <div className={styles.registerRow}>
          <CommonTextInput
            value={userData.email}
            error={errors.email}
            onChange={(event) => setValue("email", event.target.value)}
            label="Электронная почта"
            style={{ flex: 1 }}
          />
          <CustomPhoneInput
            disabled={disablePhone}
            value={userData.phone}
            error={errors.phone}
            setValue={(_phone) => setValue("phone", _phone)}
            label="Телефон"
            style={{ flex: 1 }}
          />
        </div>
        <div className={styles.registerRow}>
          {description && (
            <ModalText style={{ flex: 1, marginBottom: 16 }}>
              {description}
            </ModalText>
          )}
          <div style={{ flex: 1 }}>
            <CommonButton
              isLoading={isLoading}
              onClick={validateAndSend}
              title={sendButtonLabel || ""}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
