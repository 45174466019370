import styles from "./index.module.scss";
import { CSSProperties, PropsWithChildren } from "react";

export const ModalHeader = (
  props: PropsWithChildren<{ className?: string; style?: CSSProperties }>
) => (
  <div
    className={[styles.header, props.className].filter(Boolean).join(" ")}
    style={props.style}
  >
    {props.children}
  </div>
);

export const ModalText = (
  props: PropsWithChildren<{ className?: string; style?: CSSProperties }>
) => (
  <div
    className={[styles.text, props.className].filter(Boolean).join(" ")}
    style={props.style}
  >
    {props.children}
  </div>
);
