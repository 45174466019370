import React, { useEffect, useState } from "react";
import { ModalWrapper } from "../../components/modalWrapper/modalWrapper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CustomPhoneInput } from "../../components/TextInput/phoneInput";
import { Value as PhoneType } from "react-phone-number-input/index";
import { CommonButton } from "../../components/commonButton";
import { phoneAuthPostSmsAction } from "../../store/phoneAuth/action";
import styles from "./index.module.scss";

import { RootState } from "../../store/root-reducer";
import useUniqueDispatch, {
  isError,
  isLoading,
  useSelector,
} from "../../utils/redux-utils";
import { ModalLink } from "../../components/modalLink/modalLink";
import {
  ModalHeader,
  ModalText,
} from "../../components/modalTypography/modalTypography";

export const LoginScreen = () => {
  const navigation = useNavigate();
  const location = useLocation();

  const smsConfirmRoute = () =>
    navigation("/sms-confirm", {
      state: { mainLocation: location.state?.mainLocation, noAnim: true },
    });

  const [phone, setPhone] = useState<PhoneType | undefined>();

  const [requestCodeToken, requestCode] = useUniqueDispatch(
    phoneAuthPostSmsAction.request
  );

  const phoneAuthState = useSelector((store: RootState) => store.phoneAuth);

  const isPhoneSending = isLoading(phoneAuthState, requestCodeToken);
  const hasPhoneSendingError = isError(phoneAuthState, requestCodeToken);

  useEffect(() => {
    if (requestCodeToken && phoneAuthState.data?.token) {
      smsConfirmRoute();
    }
  }, [phoneAuthState]);

  const sendPhoneHandler = () => {
    if (phone) {
      requestCode([{ phone: phone.substring(1) }]);
    }
  };

  return (
    <ModalWrapper onClose={() => navigation("/")}>
      <div className={styles.authRow}>
        <ModalHeader style={{ flex: 1 }}>Вход</ModalHeader>
        <ModalLink to="/register">Регистрация</ModalLink>
      </div>

      <div className={styles.center}>
        <div className={styles.content}>
          <CustomPhoneInput
            value={phone}
            setValue={setPhone}
            label="Номер телефона"
            error={
              hasPhoneSendingError ? "Не удалось отправить код авторизации" : ""
            }
          />
          <CommonButton
            onClick={sendPhoneHandler}
            isLoading={isPhoneSending}
            title={"Войти"}
          />
          <div style={{ height: 12 }} />
          <ModalText>Вам придет код для входа на номер телефона.</ModalText>
        </div>
      </div>
    </ModalWrapper>
  );
};
