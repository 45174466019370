import styles from "./index.module.scss";
import { Table } from "../../components/table/table";
import { useEffect, useMemo, useState } from "react";
import { Header } from "../../components/header/header";
import { Footer } from "../../components/footer/footer";
import axios from "axios";
import { BASE_PATH } from "../../api/constants";

const formatPhone = (it: string) =>
  `+7 ${it.substring(1, 4)} ${it.substring(4, 6)}* ** **`;

const publishedAt = (val: any) =>
  val ? new Date(val).toLocaleDateString() : "-";

export const WinnersScreen = () => {
  const [results, setResults] = useState<any>([]);
  useEffect(() => {
    (async () => {
      const { data } = await axios.get(BASE_PATH + "/api/results");
      setResults(
        data.map((it: any) => ({
          publishedAt: it.publishedAt,
          name: it.winner?.firstname,
          phone: formatPhone(it.winner?.phone || "000000000"),
          prize: it.prize,
        }))
      );
    })();
  }, []);

  return (
    <>
      <Header />
      <div className={styles.container}>
        <h1 className={styles.header}>Победители</h1>
        <div className={styles.desktop}>
          <Table
            data={results}
            headers={{
              publishedAt: "Дата розыгрыша",
              // @ts-ignore
              name: "Имя",
              phone: "Контакт",
              prize: "Приз",
            }}
            renderers={{
              publishedAt,
            }}
          />
        </div>
        <div className={styles.mobile}>
          {results.map((it: any) => (
            <div className={styles.winner}>
              <div>{it.name}</div>
              <div>{it.phone}</div>
              <div>{publishedAt(it.publishedAt)}</div>
              <div>{it.prize}</div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};
