import { all } from "redux-saga/effects";
import { tokenSaga } from "./token/saga";
import { phoneAuthSaga } from "./phoneAuth/saga";
import { userSaga } from "./user/saga";
import { testsSaga } from "./tests/saga";
import { receiptsSaga } from "./receipts/saga";

export default function* rootSaga() {
  yield all([
    tokenSaga(),
    phoneAuthSaga(),
    userSaga(),
    testsSaga(),
    receiptsSaga(),
  ]);
}
