import api from "../storeConfig";
import { all, call, put, takeEvery } from "redux-saga/effects";
import {
  userLoadAction,
  userLoginAction,
  userLogoutAction,
  userUpdateAction,
} from "./action";
import { tokenSetTokenAction } from "../token/action";

export const loginRequest = function* (
  action: ReturnType<typeof userLoginAction.request>
) {
  try {
    let response = yield call(() => api.user.methods.login(...action.payload));

    yield put(tokenSetTokenAction.request([response.data.token], ""));

    if (Object.keys(action.payload[0].profileToRegister).length > 1) {
      console.log("register event");
      // @ts-ignore
      window.ym(95762061, "reachGoal", "code_check");
    }
    yield put(userLoginAction.success(response.data.user, action.meta));
  } catch (error) {
    yield put(userLoginAction.failure(error, action.meta));
  }
};

export const logoutRequest = function* (
  action: ReturnType<typeof userLogoutAction.request>
) {
  try {
    let response = yield call(() => api.user.methods.logout(...action.payload));

    yield put(tokenSetTokenAction.request([null], ""));

    yield put(userLogoutAction.success(response, action.meta));
  } catch (error) {
    yield put(userLogoutAction.failure(error, action.meta));
  }
};

export const loadRequest = function* (
  action: ReturnType<typeof userLoadAction.request>
) {
  try {
    let response = yield call(() => api.user.methods.load(...action.payload));
    yield put(userLoadAction.success(response.data, action.meta));
  } catch (error) {
    yield put(userLoadAction.failure(error, action.meta));
  }
};

export const updateRequest = function* (
  action: ReturnType<typeof userUpdateAction.request>
) {
  try {
    let response = yield call(() => api.user.methods.update(...action.payload));
    yield put(userUpdateAction.success(response.data, action.meta));
  } catch (error) {
    yield put(userUpdateAction.failure(error, action.meta));
  }
};

export const userSaga = function* () {
  yield all([
    takeEvery(userLoginAction.request, loginRequest),
    takeEvery(userLogoutAction.request, logoutRequest),
    takeEvery(userLoadAction.request, loadRequest),
    takeEvery(userUpdateAction.request, updateRequest),
  ]);
};
