import api from "../storeConfig";
import { all, call, put, takeEvery } from "redux-saga/effects";
import {
  testsGetTestListAction,
  testsGetResultListAction,
  testsGetTestAction,
  testsPostTestResultAction,
} from "./action";

export const getTestListRequest = function* (
  action: ReturnType<typeof testsGetTestListAction.request>
) {
  try {
    let response = yield call(() =>
      api.tests.methods.getTestList(...action.payload)
    );
    yield put(testsGetTestListAction.success(response.data, action.meta));
  } catch (error) {
    yield put(testsGetTestListAction.failure(error, action.meta));
  }
};

export const getResultListRequest = function* (
  action: ReturnType<typeof testsGetResultListAction.request>
) {
  try {
    let response = yield call(() =>
      api.tests.methods.getResultList(...action.payload)
    );
    yield put(testsGetResultListAction.success(response.data, action.meta));
  } catch (error) {
    yield put(testsGetResultListAction.failure(error, action.meta));
  }
};

export const getTestRequest = function* (
  action: ReturnType<typeof testsGetTestAction.request>
) {
  try {
    let response = yield call(() =>
      api.tests.methods.getTest(...action.payload)
    );
    yield put(testsGetTestAction.success(response.data, action.meta));
  } catch (error) {
    yield put(testsGetTestAction.failure(error, action.meta));
  }
};

export const postTestResultRequest = function* (
  action: ReturnType<typeof testsPostTestResultAction.request>
) {
  try {
    let response = yield call(() =>
      api.tests.methods.postTestResult(...action.payload)
    );
    yield put(testsPostTestResultAction.success(response.data, action.meta));
  } catch (error) {
    yield put(testsPostTestResultAction.failure(error, action.meta));
  }
};

export const testsSaga = function* () {
  yield all([
    takeEvery(testsGetTestListAction.request, getTestListRequest),
    takeEvery(testsGetResultListAction.request, getResultListRequest),
    takeEvery(testsGetTestAction.request, getTestRequest),
    takeEvery(testsPostTestResultAction.request, postTestResultRequest),
  ]);
};
