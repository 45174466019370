import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import Types from "./types/types";
export class Api {
  axiosInstance: AxiosInstance;

  phoneAuth = {
    postPhoneAuthSms: (
      data: Types["phoneAuth"]["postPhoneAuthSms"]["input"],
      options?: AxiosRequestConfig
    ) =>
      this.axiosInstance.post<
        any,
        AxiosResponse<Types["phoneAuth"]["postPhoneAuthSms"]["output"]>
      >(`/api/phone-auth/sms`, data, options),

    postApiPhoneAuthSmsConfirm: (
      data: Types["phoneAuth"]["postApiPhoneAuthSmsConfirm"]["input"],
      options?: AxiosRequestConfig
    ) =>
      this.axiosInstance.post<
        any,
        AxiosResponse<
          Types["phoneAuth"]["postApiPhoneAuthSmsConfirm"]["output"]
        >
      >(`/api/auth-ext/auth/phone/auth-step/0`, data, options),
  };

  user = {
    getUsersMe: (options?: AxiosRequestConfig) =>
      this.axiosInstance.get<
        any,
        AxiosResponse<Types["user"]["getUsersMe"]["output"]>
      >(`/api/users/me`, options),

    postUsersMe: (
      data: Types["user"]["postUsersMe"]["input"],
      options?: AxiosRequestConfig
    ) =>
      this.axiosInstance.post<
        any,
        AxiosResponse<Types["user"]["postUsersMe"]["output"]>
      >("/api/users/me", data, options),
  };

  receipt = {
    postNewReceipt: (
      data: Types["receipt"]["postNewReceipt"]["input"],
      options?: AxiosRequestConfig
    ) =>
      this.axiosInstance.post<
        any,
        AxiosResponse<Types["receipt"]["postNewReceipt"]["output"]>
      >("/api/new-receipt", data, options),

    getReceipts: (options?: AxiosRequestConfig) =>
      this.axiosInstance.get<
        any,
        AxiosResponse<Types["receipt"]["getReceipts"]["output"]>
      >("/api/receipts", options),
  };

  result = {
    getResults: (options?: AxiosRequestConfig) =>
      this.axiosInstance.get<
        any,
        AxiosResponse<Types["result"]["getResults"]["output"]>
      >("/api/results", options),
  };

  test = {
    getTests: (options?: AxiosRequestConfig) =>
      this.axiosInstance.get<
        any,
        AxiosResponse<Types["test"]["getTests"]["output"]>
      >("/api/tests", options),

    getTestId: (id: number, options?: AxiosRequestConfig) =>
      this.axiosInstance.get<
        any,
        AxiosResponse<Types["test"]["getTestId"]["output"]>
      >(`/api/tests/${id}`, options),

    getTestResults: (options?: AxiosRequestConfig) =>
      this.axiosInstance.get<
        any,
        AxiosResponse<Types["test"]["getTestResults"]["output"]>
      >("/api/test-results", options),

    postTestResults: (
      data: Types["test"]["postTestResults"]["input"],
      options?: AxiosRequestConfig
    ) =>
      this.axiosInstance.post<
        any,
        AxiosResponse<Types["test"]["postTestResults"]["output"]>
      >("/api/test-results", data, options),
  };

  constructor(instance: AxiosInstance = axios.create()) {
    this.axiosInstance = instance;
  }
}
const api = new Api();
export default api;
