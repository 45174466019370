import React, { useEffect, useState } from "react";
import { ModalWrapper } from "../../components/modalWrapper/modalWrapper";
import {
  ModalHeader,
  ModalText,
} from "../../components/modalTypography/modalTypography";
import { useLocation, useNavigate } from "react-router-dom";
import { CommonButton } from "../../components/commonButton";
import { CONTENT_RESTRICTION_STORAGE_KEY } from "../../api/constants";
import styles from "./modals.module.scss";
import { useUser } from "../../utils/useUser";
import { UserDataForm } from "../../components/userDataForm";
import { RegistrationDataType } from "../auth/register";
import useUniqueDispatch, {
  isLoading,
  useSelector,
} from "../../utils/redux-utils";
import { userUpdateAction } from "../../store/user/action";
import { RootState } from "../../store/root-reducer";
import _ from "lodash";

export const useForceFillingMissed = () => {
  const { user } = useUser();
  const location = useLocation();
  const onFillMissed = location.pathname === "/fill-missed";
  const navigate = useNavigate();
  useEffect(() => {
    if (user && !user.firstname && !onFillMissed) {
      navigate("/fill-missed", {
        state: { mainLocation: location, noAnim: true },
      });
    }

    if (user && user.firstname && onFillMissed) {
      navigate("/");
    }

    if (!user && onFillMissed) {
      navigate("/");
    }
  }, [user, onFillMissed]);
};

export const FillMissedScreen = () => {
  const navigate = useNavigate();
  const { user, isLoggedIn, logout } = useUser();

  const [userData, setUserData] = useState<RegistrationDataType>({
    ..._.pick(user!, [
      "firstname",
      "lastname",
      "inn",
      "company",
      "email",
      "phone",
    ]),
  });

  const [updateToken, update] = useUniqueDispatch(userUpdateAction.request);

  const updateHandler = () => {
    update([{ user: userData }]);
  };

  const userRequest = useSelector((store: RootState) => store.user);

  const isUpdating = isLoading(userRequest, updateToken);
  return (
    <ModalWrapper>
      <div className={styles.topRow}>
        <ModalHeader style={{ flex: 1 }}>Персональные данные</ModalHeader>
        <a href="#" className={styles.logout} onClick={logout}>
          Выйти
        </a>
      </div>
      <div className={styles.layout}>
        <UserDataForm
          disablePhone
          userData={userData}
          setUserData={setUserData}
          onSend={updateHandler}
          isLoading={isUpdating}
          sendButtonLabel={"Сохранить"}
        />
      </div>
    </ModalWrapper>
  );
};
