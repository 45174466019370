import api from "../storeConfig";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { tokenLoadTokenAction, tokenSetTokenAction } from "./action";

export const loadTokenRequest = function* (
  action: ReturnType<typeof tokenLoadTokenAction.request>
) {
  try {
    let response = yield call(() =>
      api.token.methods.loadToken(...action.payload)
    );
    yield put(tokenLoadTokenAction.success(response, action.meta));
  } catch (error) {
    yield put(tokenLoadTokenAction.failure(error, action.meta));
  }
};

export const setTokenRequest = function* (
  action: ReturnType<typeof tokenSetTokenAction.request>
) {
  try {
    let response = yield call(() =>
      api.token.methods.setToken(...action.payload)
    );
    yield put(tokenSetTokenAction.success(response, action.meta));
  } catch (error) {
    yield put(tokenSetTokenAction.failure(error, action.meta));
  }
};

export const tokenSaga = function* () {
  yield all([
    takeEvery(tokenLoadTokenAction.request, loadTokenRequest),
    takeEvery(tokenSetTokenAction.request, setTokenRequest),
  ]);
};
