import styles from "./index.module.scss";
import { Table } from "../../components/table/table";

const mockData = [
  {
    id: 2,
    result: null,
    createdAt: "2023-12-02T01:36:58.983Z",
    updatedAt: "2023-12-02T01:36:58.983Z",
    publishedAt: "2023-12-02T01:36:58.974Z",
  },
  {
    id: 1,
    result: null,
    createdAt: "2023-12-02T01:33:56.858Z",
    updatedAt: "2023-12-02T01:33:56.858Z",
    publishedAt: null,
  },
];

export const HistoryScreen = () => {
  return (
    <div>
      <h1 className={styles.header}>История участия в розыгрыше</h1>
      <Table
        data={mockData}
        headers={{
          createdAt: "Дата",
          publishedAt: "Дата розыгрыша",
          result: "Результат",
        }}
        renderers={{
          createdAt: (val) => new Date(val).toLocaleDateString(),
          publishedAt: (val) =>
            val ? new Date(val).toLocaleDateString() : "-",
        }}
      />
    </div>
  );
};
