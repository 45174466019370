import { tokenLoadTokenAction, tokenSetTokenAction } from "./action";
import api from "../storeConfig";
import { combineReducers } from "redux";

import { createReducer } from "typesafe-actions";
import { RequestState } from "../../libraries/packages/redux-utils/src/request/create-request-reducer";
import createLoadingReducer from "../../libraries/packages/redux-utils/src/request/create-loading-reducer";
import createOperationReducer from "../../libraries/packages/redux-utils/src/request/create-operation-reducer";
import createErrorReducer from "../../libraries/packages/redux-utils/src/request/create-error-reducer";

export const tokenReducer = combineReducers<
  RequestState<typeof api.token.type>
>({
  data: createReducer(null)
    .handleAction(
      [tokenLoadTokenAction.success],
      (state, action) => action.payload
    )
    .handleAction(
      [tokenSetTokenAction.success],
      (state, action) => action.payload
    ),
  isLoading: createLoadingReducer(tokenLoadTokenAction, tokenSetTokenAction),
  lastOpId: createOperationReducer(tokenLoadTokenAction, tokenSetTokenAction),
  error: createErrorReducer(tokenLoadTokenAction, tokenSetTokenAction),
});
