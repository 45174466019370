import React, { useEffect } from "react";
import { Header } from "../../components/header/header";
import { Footer } from "../../components/footer/footer";
import { CommonButton } from "../../components/commonButton";
import { Testing } from "./testing";
import { useUser } from "../../utils/useUser";
import styles from "./index.module.scss";
import LogoMain from "../../assets/logo-main.svg";
import PicMain1 from "../../assets/pic-main-1.png";

import Blob1 from "../../assets/blob-main-1.png";
import Blob2 from "../../assets/blob-main-2.png";
import Blob3 from "../../assets/blob-main-3.png";
import Blob4 from "../../assets/blob-main-4.png";
import Blob5 from "../../assets/blob-main-5.png";
import Blob5Mobile from "../../assets/blob-main-5-mob.png";
import Blob61 from "../../assets/blob-main-61.png";
import Blob62 from "../../assets/blob-main-62.png";
import Blob63 from "../../assets/blob-main-63.png";

import PicMain2 from "../../assets/pic-main-2.svg";
import PicMain3 from "../../assets/pic-main-3.svg";
import PicMain51 from "../../assets/pic-main-5-1.svg";
import PicMain52 from "../../assets/pic-main-5-2.svg";
import PicMain53 from "../../assets/pic-main-5-3.svg";
import PicMain54 from "../../assets/pic-main-5-4.svg";
import PicMain55 from "../../assets/pic-main-5-5.svg";
import PicMain61 from "../../assets/pic-main-6-1.png";
import PicMain62 from "../../assets/pic-main-6-2.svg";
import PicMain63 from "../../assets/pic-main-6-3.svg";

import { FAQ } from "../../components/faq";
import { RulesLink } from "../../components/rulesLink/rulesLink";
import { ButtonWrapper } from "../../components/buttonWrapper";
import { AwayLink } from "../../components/awayButton";

export const MainScreen = () => {
  const { isLoggedIn, register } = useUser();
  const participate = isLoggedIn
    ? () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    : register;

  return (
    <>
      <Header />

      <div className={styles.container}>
        {isLoggedIn ? (
          <Testing />
        ) : (
          <div className={styles.main1}>
            <div className={styles.content}>
              <div className={styles.main1__wrapper}>
                <div className={styles.main1__imageBlock}>
                  <img src={PicMain1} className={styles.lady} />
                  <img src={Blob1} className={styles.lady__background} />
                </div>

                <div className={styles.main1__textBlock}>
                  <img src={LogoMain} />
                  <div
                    className={styles.aboutProgram + " " + styles.main1__text}
                  >
                    Заботливая программа для&nbsp;офис-менеджера, который всегда
                    заботился о&nbsp;других
                  </div>
                  <CommonButton
                    className={styles.main1__button}
                    white
                    title="Участвовать"
                    onClick={participate}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className={styles.main2}>
          <div className={styles.content}>
            <a id="program" />
            <div className={styles.main2__wrapper}>
              <div className={styles.main2__imageContainer}>
                <img src={PicMain2} className={styles.hand} />
                <img src={Blob2} className={styles.hand__background} />
              </div>
              <div className={styles.block + " " + styles.main2__textBlock}>
                <div className={styles.blockHeader}>
                  Мы&nbsp;понимаем, как вам непросто
                </div>
                <p>
                  Ваша работа&nbsp;&mdash; настоящее испытание.
                  Но&nbsp;с&nbsp;приближением новогодних праздников
                  её&nbsp;становится только больше: от&nbsp;выбора корпоративных
                  подарков до&nbsp;организации лучшего корпоратива в истории
                  компании.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.main3}>
          <div className={styles.content}>
            <div className={styles.main3__wrapper}>
              <div className={styles.main3__imageContainer}>
                <img className={styles.main3__image} src={PicMain3} />
                <img src={Blob3} className={styles.main3__background} />
              </div>
              <div className={styles.block + " " + styles.main3__textBlock}>
                <h2 className={styles.blockHeader}>
                  Мы&nbsp;позаботимся <br /> о&nbsp;вас
                </h2>
                <p className={styles.text}>
                  Часть ваших забот мы&nbsp;возьмем на&nbsp;себя, чтобы вам было
                  легче пережить предновогоднюю суету и&nbsp;завершить этот год
                  на&nbsp;хорошей ноте.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.main4}>
          <div className={styles.content}>
            <div className={styles.block + " " + styles.main4__textBlock}>
              <h2 className={styles.blockHeader}>
                Почувствуйте нашу&nbsp;заботу
              </h2>
              <p>
                Чтобы разрядить большинство офисных моментов, мы&nbsp;создали
                ряд видеоуроков, в&nbsp;которых наши эксперты делятся полезными
                советами. Например, event-менеджер поведает о&nbsp;том, как
                рассчитать количество алкоголя на&nbsp;корпоратив. Бухгалтер
                поможет правильно оформить покупку алкоголя. А&nbsp;бармен
                расскажет, что и&nbsp;как пить на корпоративе, чтобы потом его
                вспомнить.
              </p>
              <img src={Blob4} className={styles.main4__background} />
            </div>
          </div>
        </div>

        <a id="mechanics" />
        <div className={styles.main5}>
          <div className={styles.content}>
            <h2 className={styles.blockHeader}>5&nbsp;простых шагов</h2>
            <p className={styles.subtitle}>
              С&nbsp;5 по&nbsp;31&nbsp;декабря примите нашу&nbsp;заботу <br />{" "}
              и&nbsp;насладитесь приятными бонусами!
            </p>

            <div className={styles.main5elements}>
              <div className={styles.main5element}>
                <div className={styles.main5element__imageContainer}>
                  <img src={PicMain51} className={styles.main5element__image} />
                </div>
                <p className={styles.main5element__title}>Зарегистрируйтесь</p>
                <p className={styles.main5element__text}>заполнив форму</p>
              </div>
              <div className={styles.main5element}>
                <div className={styles.main5element__imageContainer}>
                  <img src={PicMain52} className={styles.main5element__image} />
                </div>
                <p className={styles.main5element__title}>Посмотрите</p>
                <p className={styles.main5element__text}>доступный гайд</p>
              </div>
              <div className={styles.main5element}>
                <div className={styles.main5element__imageContainer}>
                  <img src={PicMain53} className={styles.main5element__image} />
                </div>
                <p className={styles.main5element__title}>Ответьте</p>
                <p className={styles.main5element__text}>
                  на&nbsp;вопросы и&nbsp;получите стикерпак
                </p>
              </div>
              <div className={styles.main5element}>
                <div className={styles.main5element__imageContainer}>
                  <img src={PicMain54} className={styles.main5element__image} />
                </div>
                <p className={styles.main5element__title}>Приобретайте</p>
                <p className={styles.main5element__text}>
                  продукцию на&nbsp;
                  <AwayLink /> от&nbsp;5000&nbsp;рублей
                </p>
              </div>
              <div className={styles.main5element}>
                <div className={styles.main5element__imageContainer}>
                  <img src={PicMain55} className={styles.main5element__image} />
                </div>
                <p className={styles.main5element__title}>Участвуйте</p>
                <p className={styles.main5element__text}>
                  в&nbsp;розыгрыше призов и&nbsp;открывайте новые гайды
                </p>
              </div>

              <img src={Blob5} className={styles.main5__background} />
              <img
                src={Blob5Mobile}
                className={styles.main5__backgroundMobile}
              />
            </div>
            <RulesLink />
          </div>
        </div>

        <a id="prizes" />
        <div className={styles.main6}>
          <div className={styles.content}>
            <h2 className={styles.blockHeader}>Призы</h2>
            <p className={styles.subtitle}>
              Загружайте чеки от&nbsp;
              <AwayLink /> и&nbsp;выигрывайте теплые призы
            </p>

            <div className={styles.main6elements}>
              <div className={styles.main61}>
                <img src={PicMain61} className={styles.main6elements__image} />
                <h3>Гарантированный приз</h3>
                <p>Уникальный набор стикеров</p>
                <img src={Blob61} className={styles.main6__background} />
              </div>

              <div className={styles.main62}>
                <img src={PicMain62} className={styles.main6elements__image} />
                <h3>Еженедельно</h3>
                <p>Сертификаты на&nbsp;уход за&nbsp;собой</p>
                <img src={Blob62} className={styles.main6__background} />
              </div>

              <div className={styles.main63}>
                <img src={PicMain63} className={styles.main6elements__image} />
                <h3>Главный новогодний приз!</h3>
                <p>Apple iPhone 15</p>
                <img src={Blob63} className={styles.main6__background} />
              </div>
            </div>
            <ButtonWrapper>
              <CommonButton white title="Участвовать" onClick={participate} />
            </ButtonWrapper>
          </div>
        </div>

        <div className={styles.faq}>
          <div className={styles.content}>
            <a id="faq" />
            <h2 className={styles.blockHeader}>Вопрос и ответ</h2>
            <FAQ />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
