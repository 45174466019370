import api from "../storeConfig";
import { createAsyncActionWithMeta } from "../../libraries/packages/redux-utils/src/request/action";

export const testsGetTestListAction = createAsyncActionWithMeta(
  "tests_testsGetTestListAction_REQUEST",
  "tests_testsGetTestListAction_SUCCESS",
  "tests_testsGetTestListAction_FAILURE"
)<
  Parameters<typeof api.tests.methods.getTestList>,
  typeof api.tests.type,
  Error
>();

export const testsGetResultListAction = createAsyncActionWithMeta(
  "tests_testsGetResultListAction_REQUEST",
  "tests_testsGetResultListAction_SUCCESS",
  "tests_testsGetResultListAction_FAILURE"
)<
  Parameters<typeof api.tests.methods.getResultList>,
  typeof api.tests.type,
  Error
>();

export const testsGetTestAction = createAsyncActionWithMeta(
  "tests_testsGetTestAction_REQUEST",
  "tests_testsGetTestAction_SUCCESS",
  "tests_testsGetTestAction_FAILURE"
)<Parameters<typeof api.tests.methods.getTest>, typeof api.tests.type, Error>();

export const testsPostTestResultAction = createAsyncActionWithMeta(
  "tests_testsPostTestResultAction_REQUEST",
  "tests_testsPostTestResultAction_SUCCESS",
  "tests_testsPostTestResultAction_FAILURE"
)<
  Parameters<typeof api.tests.methods.postTestResult>,
  typeof api.tests.type,
  Error
>();
