import useUniqueDispatch, { useSelector } from "./redux-utils";
import { RootState } from "../store/root-reducer";
import { useNavigate, useLocation } from "react-router-dom";
import { userLogoutAction } from "../store/user/action";

export const useUser = () => {
  const user = useSelector((store: RootState) => store.user.data);
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = Boolean(user);
  const firstName = isLoggedIn ? user?.firstname || "Пользователь" : null;
  const login = () => navigate("/login", { state: { mainLocation: location } });
  const register = () =>
    navigate("/register", { state: { mainLocation: location } });
  const [logOutToken, logOut] = useUniqueDispatch(userLogoutAction.request);
  const logout = () => logOut([]);

  return {
    user,
    firstName,
    isLoggedIn,
    login,
    register,
    logout,
  };
};
