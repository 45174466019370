import { api } from "./../api";

import { getToken, setToken } from "../api/storage";
import { sendCodeResponse } from "../api/models/sendCodeResponse";
import { UserType } from "../api/models/userType";
import { TestType } from "../api/models/testType";
import { ReceiptType } from "../api/models/receiptType";

const storeConfig = {
  token: {
    methods: {
      loadToken: getToken,
      setToken: setToken,
    },
    type: typeof {} as unknown as string | null,
  },
  phoneAuth: {
    methods: {
      postSms: api.phoneAuth.postPhoneAuthSms,
    },
    type: typeof {} as unknown as sendCodeResponse | null,
  },
  user: {
    methods: {
      login: api.phoneAuth.postApiPhoneAuthSmsConfirm,
      logout: () => null,
      update: api.user.postUsersMe,
      load: api.user.getUsersMe,
    },
    type: typeof {} as unknown as UserType | null,
  },
  tests: {
    methods: {
      getTestList: api.test.getTests,
      getResultList: api.test.getTestResults,
      getTest: api.test.getTestId,
      postTestResult: api.test.postTestResults,
    },
    type: typeof {} as unknown as TestType[],
  },
  receipts: {
    methods: {
      getReceiptsList: api.receipt.getReceipts,
      postReceipt: api.receipt.postNewReceipt,
    },
    type: typeof {} as unknown as ReceiptType[],
  },
};

export default storeConfig;
