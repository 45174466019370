import api from "../storeConfig";
import { createAsyncActionWithMeta } from "../../libraries/packages/redux-utils/src/request/action";

export const receiptsGetReceiptsListAction = createAsyncActionWithMeta(
  "receipts_receiptsGetReceiptsListAction_REQUEST",
  "receipts_receiptsGetReceiptsListAction_SUCCESS",
  "receipts_receiptsGetReceiptsListAction_FAILURE"
)<Parameters<typeof api.receipts.methods.getReceiptsList>, any, Error>();

export const receiptsPostReceiptAction = createAsyncActionWithMeta(
  "receipts_receiptsPostReceiptAction_REQUEST",
  "receipts_receiptsPostReceiptAction_SUCCESS",
  "receipts_receiptsPostReceiptAction_FAILURE"
)<Parameters<typeof api.receipts.methods.postReceipt>, any, Error>();
