import React, { ReactNode, useCallback, useEffect, useRef } from "react";
import styles from "./modalWrapper.module.scss";
import { useLocation } from "react-router-dom";

export const ModalWrapper = ({
  style,
  children,
  onClose,
  noAnim,
}: {
  style?: any;
  children: ReactNode;
  onClose?: () => void;
  noAnim?: boolean;
}) => {
  const location = useLocation();
  // should've used react-modal
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const add = () => ref.current?.classList.add(styles.visible);
    if (noAnim || location.state?.noAnim) {
      add();
    } else {
      requestAnimationFrame(add);
    }
  }, []);

  const close = useCallback(() => {
    if (!onClose) return;
    ref.current?.classList.remove(styles.visible);
    setTimeout(() => onClose(), 200);
  }, []);

  return (
    <div className={styles.modal} ref={ref} style={style}>
      <div onClick={close} className={styles.overlay} />
      <div className={styles.content}>{children}</div>
    </div>
  );
};
