import React from "react";
import VerificationInput from "react-verification-input";
import styles from "./codeInput.module.scss";
export const CodeInput = ({
  value,
  onChange,
}: {
  value?: string;
  onChange?: (val: string) => void;
}) => {
  return (
    <VerificationInput
      validChars={"0-9"}
      length={4}
      placeholder={" "}
      value={value}
      onChange={onChange}
      classNames={{
        container: styles.container,
        character: styles.character,
        characterInactive: styles.character__selected,
        characterSelected: styles.character__selected,
      }}
    />
  );
};
