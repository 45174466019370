import styles from "./index.module.scss";
import { Table } from "../../components/table/table";
import useUniqueDispatch, {
  isLoading,
  useSelector,
} from "../../utils/redux-utils";
import { receiptsGetReceiptsListAction } from "../../store/receipts/action";
import { RootState } from "../../store/root-reducer";
import { useEffect } from "react";
import { LoadingIndicator } from "../../components/commonButton";

export const ReceiptsScreen = () => {
  const [loadReceiptsToken, loadReceipts] = useUniqueDispatch(
    receiptsGetReceiptsListAction.request
  );

  const receiptsState = useSelector((store: RootState) => store.receipts);

  const isReceiptsLoading = isLoading(receiptsState, loadReceiptsToken);

  useEffect(() => {
    loadReceipts([{}]);
  }, []);

  return (
    <div>
      <h1 className={styles.header}>Чеки</h1>
      {isReceiptsLoading ? (
        <LoadingIndicator />
      ) : (
        receiptsState.data && (
          <Table
            data={receiptsState.data}
            headers={{
              createdAt: "Дата загрузки",
              status: "Состояние",
            }}
            renderers={{
              createdAt: (val) => new Date(val).toLocaleDateString(),
              status: (val) =>
                ({
                  processing: "В обработке",
                  blocked: "Отклонено",
                  confirmed: "Подтверждено",
                }[val]),
            }}
          />
        )
      )}
    </div>
  );
};
