import styles from "./index.module.scss";
import { Link } from "react-router-dom";
import { WINELAB_AWAY_URL } from "../../api/constants";

export const Footer = () => (
  <div className={styles.container}>
    <div className={styles.links}>
      <Link to="/rules#top" className={styles.link}>
        Правила акции
      </Link>
      <Link to="/privacy-policy#top" className={styles.link}>
        Политика&nbsp;обработки персональных&nbsp;данных
      </Link>
      <a href={WINELAB_AWAY_URL} className={styles.link} target="_blank">
        Сайт ВИНЛАБ БИЗНЕС
      </a>
      <a
        href="mailto:support@winelab-care.ru"
        className={styles.link}
        target="_blank"
      >
        Связаться с&nbsp;нами
      </a>
      <span className={styles.copyright}>+7 (495) 646-91-21</span>
    </div>

    <p className={styles.copyright}>
      Реклама, ООО «Винлаб-Запад», ИНН 7719191685, erid: 2SDnjcYkzik
    </p>

    <p className={styles.copyright}>© 2023 ВИНЛАБ БИЗНЕС</p>
  </div>
);
