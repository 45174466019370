import styles from "./index.module.scss";
import { ReactComponent as Lock } from "../../assets/lock-white.svg";
import { ReactComponent as Play } from "../../assets/play-white.svg";
import { useLayoutEffect, useRef, useState } from "react";

export const Video = ({
  previewUrl,
  videoUrl,
  locked,
}: {
  previewUrl: string;
  videoUrl: string;
  locked?: boolean;
}) => {
  const [isOpen, setOpen] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  useLayoutEffect(() => {
    if (videoRef.current) {
      if (isOpen) {
        videoRef.current.play();
        videoRef.current.style.width = "";
      } else {
        videoRef.current.pause();
        videoRef.current.style.width = "0";
      }
    }
  }, [videoRef, isOpen]);
  return (
    <div
      onClick={!locked ? () => setOpen(true) : () => {}}
      className={[styles.container, locked ? styles.lock : ""].join(" ")}
      style={{ backgroundImage: `url(${previewUrl})` }}
    >
      {!locked && <video src={videoUrl} ref={videoRef} controls />}
      {isOpen ? <></> : <>{locked ? <Lock /> : <Play />}</>}
    </div>
  );
};
