import {
  ActionCreatorBuilder,
  createAsyncAction,
  TypeConstant,
} from 'typesafe-actions'

declare type AsyncActionHandler<TPayloadMeta> = ActionCreatorBuilder<
  TypeConstant,
  unknown extends TPayloadMeta
    ? any
    : [TPayloadMeta] extends [[infer T, any]]
    ? T
    : TPayloadMeta,
  unknown extends TPayloadMeta
    ? undefined
    : [TPayloadMeta] extends [[any, infer T]]
    ? T
    : undefined
>

type Action<TPayload extends any = any> = AsyncActionHandler<TPayload>

export default Action

export function createAsyncActionWithMeta<
  TType1 extends TypeConstant,
  TType2 extends TypeConstant,
  TType3 extends TypeConstant,
  TType4 extends TypeConstant = never,
>(
  requestArg: TType1,
  successArg: TType2,
  failureArg: TType3,
  cancelArg?: TType4,
) {
  return function <T1, T2, T3, T4 = unknown>() {
    return createAsyncAction<TType1, TType2, TType3, TType4>(
      requestArg,
      successArg,
      failureArg,
      cancelArg,
    )<
      T1 extends [infer T11, infer T12] ? [T11, T12] : [T1, string | undefined],
      T2 extends [infer T21, infer T22] ? [T21, T22] : [T2, string | undefined],
      T3 extends [infer T31, infer T32] ? [T31, T32] : [T3, string | undefined]
    >()
  }
}
