import styles from "./index.module.scss";
import { PropsWithChildren } from "react";

export const ButtonWrapper = (props: PropsWithChildren) => (
  <div className={styles.buttonWrapper}>
    <div />
    {props.children}
    <div />
  </div>
);
