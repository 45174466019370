import React, { CSSProperties } from "react";
import styles from "./index.module.scss";
import ReactLoading from "react-loading";
import { WINELAB_AWAY_URL } from "../../api/constants";

export const AwayButton = ({ style }: { style?: CSSProperties }) => {
  return (
    <a
      style={style}
      className={styles.container}
      href={WINELAB_AWAY_URL}
      target="_blank"
    >
      К покупкам
      <div style={{ width: 8 }} />
      <svg
        width="17"
        height="18"
        viewBox="0 0 17 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.7955 9.7955C16.2348 9.35616 16.2348 8.64385 15.7955 8.20451L8.63604 1.04505C8.1967 0.60571 7.48439 0.60571 7.04505 1.04505C6.60571 1.48439 6.60571 2.1967 7.04505 2.63604L13.409 9L7.04505 15.364C6.60571 15.8033 6.60571 16.5156 7.04505 16.955C7.48439 17.3943 8.1967 17.3943 8.63604 16.955L15.7955 9.7955ZM-1.20381e-07 10.125L15 10.125L15 7.875L1.20381e-07 7.875L-1.20381e-07 10.125Z"
          fill="#75B843"
        />
      </svg>
    </a>
  );
};

export const AwayLink = ({ style }: { style?: CSSProperties }) => {
  return (
    <a
      style={style}
      className={styles.link}
      href={WINELAB_AWAY_URL}
      target="_blank"
    >
      ВИНЛАБ&nbsp;БИЗНЕС
    </a>
  );
};
