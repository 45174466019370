const USER_TOKEN_KEY = "USER_TOKEN_KEY";

export async function getToken() {
  const token = await localStorage.getItem(USER_TOKEN_KEY);
  console.log("GET TOKEN", token);
  return token ?? "";
}

export async function setToken(token?: string) {
  token
    ? await localStorage.setItem(USER_TOKEN_KEY, token)
    : await localStorage.removeItem(USER_TOKEN_KEY);

  return token;
}
