import React from "react";
import { Header } from "../../components/header/header";
import { Footer } from "../../components/footer/footer";
import styles from "./index.module.scss";

export const PrivacyPolicyScreen = () => (
  <>
    <Header />
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>Политика в отношении обработки персональных данных</h1>
        <p>
          1. Настоящая политика обработки персональных данных составлена в
          соответствии с требованиями Федерального закона от 27.07.2006. №152-ФЗ
          &laquo;О персональных данных&raquo; и определяет порядок обработки
          персональных данных, предоставленных пользователями веб-сайта
          winelab-care.ru (далее &ndash; Сайт) его владельцу (далее &ndash;
          Оператор).
        </p>
        <p>2. Определения основных понятий, используемых в Политике:</p>
        <p>
          Обработка персональных данных &ndash; любое действие (операция) или
          совокупность действий (операций), совершаемых с использованием средств
          автоматизации или без использования таких средств с персональными
          данными, включая сбор, запись, систематизацию, накопление, хранение,
          уточнение (обновление, изменение), извлечение, использование, передачу
          (распространение, предоставление, доступ), обезличивание,
          блокирование, удаление, уничтожение персональных данных;
        </p>
        <p>
          Персональные данные &ndash; любая информация, относящаяся прямо или
          косвенно относящаяся к пользователям веб-сайта;
        </p>
        <p>Пользователь &ndash; любой посетитель веб-сайта winelab-care.ru;</p>
        <p>
          3. Оператор может обрабатывать следующие персональные данные
          Пользователя, предоставленные Пользователем путем заполнения форм на
          Сайте:
        </p>
        <ul>
          <li>Фамилия, имя, отчество;</li>
          <li>Номер телефона;</li>
          <li>Адрес электронной почты;</li>
          <li>ИНН</li>
          <li>Компания (место работы)</li>
          <li>
            Иные персональные данные, которые Пользователь пожелает оставить на
            Сайте.
          </li>
        </ul>
        <p>4. Цели обработки персональных данных</p>
        <p>
          Цель обработки персональных данных Пользователя &mdash; заключение,
          исполнение и прекращение гражданско-правовых договоров; уточнение
          деталей заказа; идентификация Пользователя на Сайте; предоставление
          Пользователю обратной связи по его запросам, направленным Оператору
          посредством веб-сайта; проведение промо-мероприятий, в которых
          участвует Пользователь.
        </p>
        <p>
          Также Оператор имеет право направлять Пользователю уведомления о новых
          продуктах и услугах, специальных предложениях и различных событиях.
          Пользователь всегда может отказаться от получения информационных
          сообщений, направив Оператору соответствующее письмо на электронный
          адрес support@winelab-care.ru.
        </p>
        <p>5. Правовые основания обработки персональных данных</p>
        <p>
          Оператор обрабатывает персональные данные Пользователя только в случае
          их заполнения и/или отправки Пользователем самостоятельно через
          специальные формы, расположенные на веб-сайте winelab-care.ru.
          Заполняя соответствующие формы и/или отправляя свои персональные
          данные Оператору, Пользователь выражает свое согласие с данной
          Политикой и с условиями обработки персональных данных. В случае
          несогласия с условиями Политики Пользователь должен прекратить
          использование Сайта.
        </p>
        <p>
          6. Срок обработки персональных данных является неограниченным.
          Пользователь соглашается с тем, что Оператор вправе передавать
          персональные данные третьим лицам.
        </p>
        <p>
          Персональные данные Пользователя могут быть переданы уполномоченным
          органам государственной власти Российской Федерации только по
          основаниям и в порядке, установленным законодательством Российской
          Федерации. Оператор принимает необходимые организационные и
          технические меры для защиты персональной информации Пользователя от
          неправомерного или случайного доступа, уничтожения, изменения,
          блокирования, копирования, распространения, а также от иных
          неправомерных действий третьих лиц.
        </p>
        <p>
          Пользователь вправе требовать от Оператора уточнения его персональных
          данных, их блокирования или уничтожения в случае, если персональные
          данные являются неполными, устаревшими, неточными, незаконно
          полученными или не являются необходимыми для заявленной цели
          обработки, а также принимать предусмотренные законом меры по защите
          своих прав.
        </p>
        <p>
          Пользователь может в любой момент отозвать свое согласие на обработку
          персональных данных, направив Оператору уведомление посредством
          электронной почты на электронный адрес Оператора
          support@winelab-care.ru с пометкой &laquo;Отзыв согласия на обработку
          персональных данных&raquo;.
        </p>
        <p>
          7. Все вопросы, связанные с обработкой персональных данных, не
          урегулированные настоящей Политикой, разрешаются в соответствии с
          действующим законодательством Российской Федерации в области
          персональных данных.
        </p>
        <p>
          Оператор вправе вносить изменения в настоящую Политику без согласия
          Пользователя.
        </p>
        <p>
          Редакция Политики вступает в силу с момента ее размещения на Сайте
          winelab-care.ru, если иное не предусмотрено новой редакцией Политики.
        </p>
        <p>
          Действующая Политика размещена в сети Интернет по адресу
          winelab-care.ru.
        </p>
        <p>
          Дата размещения в сети Интернет и вступления в силу настоящей
          редакции: &laquo;05&raquo; декабря 2023 г.
        </p>
      </div>
    </div>

    <Footer />
  </>
);
