import React, { useEffect } from "react";
import "./App.css";
import { MainNavigation } from "./screens/";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import useUniqueDispatch, {
  isLoading,
  MobileContext,
  useSelector,
} from "./utils/redux-utils";
import { RootState } from "./store/root-reducer";
import { userLoadAction } from "./store/user/action";
import { useContentRestriction } from "./screens/main/contentRestriction";
import { useForceFillingMissed } from "./screens/main/fillMissed";

const AppWrapper = () => {
  const token = useSelector((store: RootState) => store.token.data);

  const [loadMeToken, loadMe] = useUniqueDispatch(userLoadAction.request);

  const userState = useSelector((store: RootState) => store.user);

  const isInitLoading = isLoading(userState, loadMeToken);

  useEffect(() => {
    if (token) {
      loadMe([]);
    }
  }, [token]);

  useContentRestriction();
  useForceFillingMissed();

  return <MainNavigation />;
};

function App() {
  return (
    <Provider context={MobileContext} store={store}>
      <BrowserRouter>
        <AppWrapper />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
