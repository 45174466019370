import React, { CSSProperties, forwardRef } from "react";
import styles from "./commonTextInput.module.scss";
import { CodeInput } from "./codeInput";

export const CommonTextInput = forwardRef(
  (
    {
      style,
      label,
      error,
      codeMode = false,
      onTextChange,
      ...props
    }: {
      onTextChange?: (val: string) => void;
      style?: CSSProperties;
      label?: string;
      codeMode?: boolean;
      error?: string;
    } & React.InputHTMLAttributes<HTMLInputElement>,
    ref: React.LegacyRef<HTMLInputElement>
  ) => {
    return (
      <div className={styles.wrapper} style={style}>
        <div
          className={[styles.container, error ? styles.error : ""].join(" ")}
        >
          {label && <div className={styles.label}>{label}</div>}
          {codeMode ? (
            <CodeInput onChange={onTextChange} value={props.value} />
          ) : (
            <input {...props} className={styles.input} ref={ref} type="text" />
          )}
        </div>
        {error && <div className={styles.error}>{error}</div>}
      </div>
    );
  }
);
