import React, { useCallback, useEffect, useState } from "react";
import { ModalWrapper } from "../../components/modalWrapper/modalWrapper";
import { useNavigate } from "react-router-dom";
import { CommonTextInput } from "../../components/TextInput/commonTextInput";
import { CommonButton } from "../../components/commonButton";
import useUniqueDispatch, {
  isError,
  isLoading,
  useSelector,
} from "../../utils/redux-utils";
import { RootState } from "../../store/root-reducer";
import { userLoginAction } from "../../store/user/action";
import {
  ModalHeader,
  ModalText,
} from "../../components/modalTypography/modalTypography";
import styles from "./index.module.scss";
import useCountDown from "../../utils/useCountdown";
import { phoneAuthPostSmsAction } from "../../store/phoneAuth/action";
import { CodeInput } from "../../components/TextInput/codeInput";

export const SmsConfirmScreen = () => {
  const navigate = useNavigate();

  const [code, setCode] = useState("");

  const authRequest = useSelector((store: RootState) => store.phoneAuth);

  const userState = useSelector((store: RootState) => store.user);

  const [sendCodeToken, sendCode] = useUniqueDispatch(userLoginAction.request);

  const isSending = isLoading(userState, sendCodeToken);
  const isCodeWrong = isError(userState, sendCodeToken);

  const sendCodeHandler = () => {
    if (authRequest.data && code.length === 4) {
      sendCode([
        {
          providerArgs: {
            phone: authRequest.data.phone,
            code,
            token: authRequest.data.token,
          },
          profileToRegister: {
            ...(authRequest.data?.registerData || {}),
            phone: authRequest.data.phone,
          },
        },
      ]);
    }
  };

  const [requestCodeToken, requestCode] = useUniqueDispatch(
    phoneAuthPostSmsAction.request
  );

  const isResending = isLoading(authRequest, requestCodeToken);

  const resendHandler = () => {
    requestCode([
      {
        ...(authRequest.data?.registerData || {}),
        phone: authRequest.data?.phone,
      },
    ]);
  };

  const [countdown, start, stop] = useCountDown();

  useEffect(() => {
    start(60);
  }, []);

  const requestNewCode = useCallback(() => {
    resendHandler();
    start(60);
  }, []);

  return (
    <ModalWrapper onClose={() => navigate("/")}>
      <div className={styles.center}>
        <div className={styles.content}>
          <ModalHeader className={styles.confirmHeader}>
            Введите код из СМС
          </ModalHeader>

          <CommonTextInput
            label="Код"
            onTextChange={(text) => setCode(text)}
            value={code}
            codeMode
            error={isCodeWrong ? "Неправильный код" : ""}
          />

          <CommonButton
            onClick={sendCodeHandler}
            isLoading={isSending}
            title={"Войти"}
          />
          <div style={{ height: 12 }} />
          {countdown > 0 ? (
            <ModalText>Новый код - через {countdown} сек.</ModalText>
          ) : (
            <CommonButton
              isLoading={isResending}
              title="Запросить код"
              onClick={requestNewCode}
              white
            />
          )}
        </div>
      </div>
    </ModalWrapper>
  );
};
