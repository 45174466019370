import { Link, LinkProps, useLocation } from "react-router-dom";
import { useMemo } from "react";
import styles from "./index.module.scss";

export const ModalLink = (props: LinkProps) => {
  const location = useLocation();
  const state = useMemo(
    () => ({
      noAnim: true,
      mainLocation: location.state?.mainLocation,
      ...(props.state || {}),
    }),
    [props.state]
  );
  const className = useMemo(
    () => [styles.link, props.className].filter(Boolean).join(" "),
    [props.className]
  );

  return <Link {...props} state={state} className={className} />;
};
