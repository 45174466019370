import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ModalWrapper } from "../../components/modalWrapper/modalWrapper";
import { ModalLink } from "../../components/modalLink/modalLink";
import {
  ModalHeader,
  ModalText,
} from "../../components/modalTypography/modalTypography";
import styles from "./index.module.scss";
import useUniqueDispatch, {
  isLoading,
  useSelector,
} from "../../utils/redux-utils";
import { phoneAuthPostSmsAction } from "../../store/phoneAuth/action";

import { RootState } from "../../store/root-reducer";
import { UserDataForm } from "../../components/userDataForm";

export type RegistrationDataType = {
  firstname: string;
  lastname: string;
  inn: string;
  company: string;
  email: string;
  phone: string;
};

export const registerStateDefault: RegistrationDataType = {
  firstname: "",
  lastname: "",
  inn: "",
  company: "",
  email: "",
  phone: "",
};

export const RegisterScreen = () => {
  const navigation = useNavigate();

  const [registerState, setRegisterState] =
    useState<RegistrationDataType>(registerStateDefault);

  const [requestCodeToken, requestCode] = useUniqueDispatch(
    phoneAuthPostSmsAction.request
  );

  const authState = useSelector((store: RootState) => store.phoneAuth);

  const registerHandler = () => {
    requestCode([{ ...registerState, phone: registerState.phone.slice(1) }]);
  };

  const location = useLocation();

  const smsConfirmRoute = () =>
    navigation("/sms-confirm", {
      state: { mainLocation: location.state?.mainLocation, noAnim: true },
    });

  const phoneAuthState = useSelector((store: RootState) => store.phoneAuth);

  useEffect(() => {
    if (requestCodeToken && phoneAuthState.data?.token) {
      // @ts-ignore
      window.ym(95762061, "reachGoal", "check-in");
      smsConfirmRoute();
    }
  }, [phoneAuthState]);

  const isSending = isLoading(authState, requestCodeToken);

  return (
    <ModalWrapper onClose={() => navigation("/")}>
      <div className={styles.authRow}>
        <ModalHeader style={{ flex: 1 }}>Регистрация</ModalHeader>
        <ModalLink to="/login">Вход</ModalLink>
      </div>
      <UserDataForm
        description={
          "Нажимая кнопку зарегистрироваться, вы даете свое согласие на обработку персональных данных."
        }
        sendButtonLabel={"Зарегистрироваться"}
        isLoading={isSending}
        onSend={registerHandler}
        userData={registerState}
        setUserData={setRegisterState}
      />
    </ModalWrapper>
  );
};
